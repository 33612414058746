import React, { Fragment, useState, useCallback, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Role from './interfaces';
import SmallErrorMessage from '../../../components/Forms/SmallErrorMessage';
import { AlertContext } from '../../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import accessControlManagementApiClient from '../../../apiClient/AccessControlManagementClient';
import CancelSaveButtons from '../../../components/Forms/CancelSaveButtons';
import Select from 'react-select';
import { rightsList } from '../../../auth/RightsUtils'
import { $enum } from "ts-enum-util";
import { Rights } from '../../../auth/Rights';
import { SelectComponentStyle } from '../../../components/Styles/SelectComponentStyle';

interface EditRoleFormProps {
    role: Role,
    onCancelClick: (event: any) => void,
    onRoleEdited: (role: Role) => void,
}

const EditRoleForm = (props: EditRoleFormProps) => {
    const role = props.role;

    const alertContext = useContext(AlertContext);

    const [roleType, setRoleType] = useState(role.type);
    const [description, setDescription] = useState(role.description);
    const [rights, setRights] = useState(role.rights);

    const [typeIsInvalid, setTypeIsInvalid] = useState(false);
    const [descriptionIsInvalid, setDescriptionIsInvalid] = useState(false);
    const [rightsAreInvalid, setRightsAreInvalid] = useState(false);

    const { t } = useTranslation();

    let configNotChanged = false;

    if (role.type === roleType
        && role.description === description
        && JSON.stringify(role.rights) === JSON.stringify(rights)) {
        configNotChanged = true;
    }

    const handleSaveButtonClick = useCallback((event: any) => {
        event.preventDefault();

        let formIsValid = true;

        if (roleType.trim() === '') {
            setTypeIsInvalid(true);
            formIsValid = false;
        }
        else {
            setTypeIsInvalid(false);
        }

        if (description.trim() === '') {
            setDescriptionIsInvalid(true);
            formIsValid = false;
        }
        else {
            setDescriptionIsInvalid(false);
        }

        if (rights === undefined || rights.length === 0) {
            setRightsAreInvalid(true);
            formIsValid = false;
        }
        else {
            setRightsAreInvalid(false);
        }

        if (formIsValid) {
            const updateRoleRequest = {
                type: roleType,
                description: description,
                rights: rights
            };
            accessControlManagementApiClient.updateRole(role.id, updateRoleRequest)
                .then((response: Role) => {
                    props.onRoleEdited(response);
                    alertContext.showSuccessAlert(t('updated role', { name: roleType }));
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(reason.message);
                });
        }
        else {
            alertContext.showErrorAlert(t("provide all mandatory fields"));
        }
    }, [props, role.id, roleType, description, rights]);

    const setRightsOnChangeHandler = useCallback(
        (right) => {
            setRights(right?.map((right: any, i: any) => right.value));
        }, []);
    return (
        <Fragment>
            <td>
                <Form.Control
                    type="text"
                    placeholder={t("enter role type")}
                    value={roleType}
                    onChange={(event) => setRoleType(event.target.value)}
                    autoComplete="off"
                />
                <SmallErrorMessage show={typeIsInvalid} message={t("role type can't be empty")} />
            </td>
            <td>
                <Form.Control
                    type="text"
                    placeholder={t("enter role description")}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    autoComplete="off"
                />
                <SmallErrorMessage show={descriptionIsInvalid} message={t("project description can't be empty")} />
            </td>
            <td>
                <Form.Group controlId="rights" className="required">
                    <Select
                        id="rights"
                        options={rightsList}
                        defaultValue={
                            rights === undefined ? [] :
                                rights.map(right => ({ value: right, label: $enum(Rights).getKeyOrDefault(right, "") }))
                        }
                        isMulti
                        styles={SelectComponentStyle}
                        onChange={setRightsOnChangeHandler}
                    />
                    <SmallErrorMessage show={rightsAreInvalid} message={t("rights list can't be empty")} />
                </Form.Group>
            </td>
            <td>
                <Form.Control
                    readOnly
                    defaultValue={role.updateTime}
                />
            </td>
            <td>
                <CancelSaveButtons
                    onCancelClick={props.onCancelClick}
                    onSaveClick={handleSaveButtonClick}
                    configNotChanged={configNotChanged}
                />
            </td>
        </Fragment>
    );
}

export default EditRoleForm;
