import React, { useContext } from 'react';
import { IfAnyGranted } from 'react-authorization'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

interface AuthorizedNavDropdownItemProps {
    title: string
    toPath: string
    expectedRights: string[]
    dataTestId: string
}

const AuthorizedNavDropdownItem = (props: AuthorizedNavDropdownItemProps) => {

    const authContext = useContext(AuthContext);

    return (
        <IfAnyGranted expected={props.expectedRights} actual={authContext.userRights}>
            <NavDropdown.Item as={NavLink} to={props.toPath} data-testid={props.dataTestId}>{props.title}</NavDropdown.Item>
        </IfAnyGranted>
    )
}

export default AuthorizedNavDropdownItem;
