import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../../context/AlertContext';
import Spinner from '../../../components/UI/Spinner';
import projectsCache from './projectsCache';
import User from './interfaces';
import Project from './interfaces';

interface ProjectByProjectIdProps {
    projectId: string,
    getAttrFunc: (p: User|undefined, t: (msg: string) => string) => string
}

export const getProjectName = (p: User|undefined, t: (msg: string) => string) => {
    if (p === undefined) {
        return t("project not found");
    }
    return p.name;
}

export const getUserId = (p: Project|undefined, t: (msg: string) => string) => {
    if (p === undefined) {
        return t("project not found");
    }
    return p.userId;
}

export const getProjectType = (p: Project|undefined, t: (msg: string) => string) => {
    if (p === undefined) {
        return t("project not found");
    }
    return p.projectType;
}

export const ProjectByProjectId = (props: ProjectByProjectIdProps) => {
    const [loading, setLoading] = useState(true);
    const alertContext = useContext(AlertContext);

    const { t } = useTranslation();

    useEffect(() => {
        projectsCache.reload(setLoading)
            .catch((reason) => {
                alertContext.showErrorAlert(t("error in processing response from server", { error: reason }));
            })
    }, [loading]);

    let content = null;

    if (loading) {
        content = <Spinner />;
    }
    else {
        let userId = props.getAttrFunc(projectsCache.getObjectById(props.projectId), t);
        content = <div>{ userId }</div>
    }

    return (
        <Fragment>
            { content }
        </Fragment>
    )
}
