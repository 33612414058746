import React from 'react'
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import SelectSearch from '../../../components/Forms/SelectSearch';
import usersCache from './usersCache';
import User from './interfaces';

interface FilterByUserNameAndAccountProps {
    userId: string
    setUserId: (id: string) => void
}

const FilterByUserNameAndAccount = (props: FilterByUserNameAndAccountProps) => {
    const { t } = useTranslation();

    return (<Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 5 }}>
        <Row noGutters={false} style={{ paddingBottom: 10 }}>
            <Col md="auto" className="my-auto">
                {t("enter user name")}
            </Col>
            <Col>
                <SelectSearch<User>
                    value={props.userId}
                    onChange={props.setUserId}
                    placeholder={""}
                    showClearButton={false}
                    idAsLabel={false}
                    cache={usersCache}
                    classNamePrefix="user"
                />
            </Col>
            <Col md="auto" className="my-auto">
                {t("enter email")}
            </Col>
            <Col>
                <SelectSearch<User>
                    value={props.userId}
                    onChange={props.setUserId}
                    placeholder={""}
                    showClearButton={true}
                    idAsLabel={false}
                    cache={usersCache}
                    classNamePrefix="userAccount"
                    filterByAttribute={(inputValue: String, objects: Array<User>) => {
                        return objects.filter((obj: User) => obj.email.toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase()))
                    }}
                    getLabel={(user: User) => user.email}
                />
            </Col>
        </Row>
        </Container>)
}

export default FilterByUserNameAndAccount;