import { getProjectType } from "./DataIngestion/Projects/ProjectByProjectId";
import projectsCache from "./DataIngestion/Projects/projectsCache";
import { AutoTrackingOptions, webAutoTrackingOptions, mobileAutoTrackingOptions } from "./DataIngestion/SdkConfiguration/AutoTrackingMode";
import BaseInfo from "./interfaces";

export function byDateDescOrdering(): ((firstItem: BaseInfo, secondItem: BaseInfo) => number) | undefined {
    return (a, b) => new Date(b.updateTime).getTime() - new Date(a.updateTime).getTime();
}

export function checkIsWebTypeProject(projectId: string, t: (msg: string) => string): boolean {
    return getProjectType(projectsCache.getObjectById(projectId), t) === "Web";
}

export function getAutoTrackingOptions(projectId: string, t: (msg: string) => string): AutoTrackingOptions {
    return checkIsWebTypeProject(projectId, t) ? webAutoTrackingOptions : mobileAutoTrackingOptions;
}

export function defineAutoTrackMode(autoTrackModeValue: number, isWebProject: boolean): number {
    //PageClick can not exists without PageView. When more requirements like that will appear then refactor this function.
    return isWebProject && autoTrackModeValue === 2 ? 3 : autoTrackModeValue
}


export function replaceSpacesWithSoftBreaks(text: string): string {
    return text.replace(/\s+/g, '\u200B');
}