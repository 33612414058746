import React, { useContext, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { Route, Switch } from 'react-router-dom';
import Layout from './hoc/Layout/Layout';
import registerFaIcons from './commons/registerFaIcons';
import { AuthContext } from './context/AuthContext';
import Login from './containers/Auth/Login';
import Logout from './containers/Auth/Logout';
import Dashboard from './containers/Dashboard/Dashboard';
import Funnel from './containers/Analysis/Funnel';
import Behavior from './containers/Analysis/Behavior';
import AdHocQuery from './containers/Analysis/AdHocQuery';
import Projects from './containers/DataIngestion/Projects/Projects';
import SdkConfiguration from './containers/DataIngestion/SdkConfiguration/SdkConfiguration';
import MetadataManagement from './containers/DataIngestion/MetadataManagement';
import LogQuery from './containers/DataIngestion/LogQuery/LogQuery';
import Users from './containers/AccessManagement/Users/Users';
import Roles from './containers/AccessManagement/Roles/Roles';
import './i18n'

registerFaIcons();

const reauthenticateInterval = 5000; // check every 5 seconds, if user should be reuathenticated
const useReauthenticateInterval = (callback: any, delay: number) => {
  const authContext = useContext(AuthContext);

  const savedCallback = useRef();

  useEffect(() => savedCallback.current = callback, [callback]);

  useEffect(() => {
    let id = setInterval(() => {
      if (authContext.isAuthenticated) {
        authContext.reauthenticate()
      }
    }, delay);
    return () => clearInterval(id);
  }, [delay, authContext]);
}

function App() {
  const authContext = useContext(AuthContext);
  useReauthenticateInterval(() => { }, reauthenticateInterval);

  return (
      <Layout>
        <Switch>
          {!authContext.isAuthenticated && <Route path="/login" component={Login} />}
          {authContext.isAuthenticated && <Route path="/logout" component={Logout} />}
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/analysis/funnel" component={Funnel} />
          <Route path="/analysis/behavior" component={Behavior} />
          <Route path="/analysis/query" component={AdHocQuery} />
          <Route path="/ingestion/projects" component={Projects} />
          <Route path="/ingestion/metadata" component={MetadataManagement} />
          <Route path="/ingestion/sdk/config" component={SdkConfiguration} />
          <Route path="/ingestion/log" component={LogQuery} />
          <Route path="/access/users" component={Users} />
          <Route path="/access/role" component={Roles} />
        </Switch>
      </Layout>
  );
}

export default App;
