import React, { Fragment, useCallback, useState, useContext } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import DeleteConfirmationModal from '../../../components/Modal/DeleteConfirmationModal';
import { AlertContext } from '../../../context/AlertContext';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import Role from './interfaces'
import accessControlManagementApiClient from '../../../apiClient/AccessControlManagementClient';
import DateTimeInTimezone from '../../../utils/DatetimeTz';
import RightsDisplay from './RightsDisplay';
import { IfGranted } from 'react-authorization'
import { Rights } from '../../../auth/Rights';

interface RoleRowProps {
    role: Role,
    onEditClick: (event: any) => void,
    onRoleDeleted: (roleId: string) => void,
}

const RoleRow = (props: RoleRowProps) => {
    const authContext = useContext(AuthContext);

    const role = props.role;

    const alertContext = useContext(AlertContext);

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const { t } = useTranslation();

    const handleDeleteButtonClicked = useCallback((event: any) => {
        event.preventDefault();

        accessControlManagementApiClient.deleteRole(role.id)
            .then((response: any) => {
                props.onRoleDeleted(role.id);
                alertContext.showSuccessAlert(t('removed role', { type: role.type }));
            })
            .catch((reason) => {
                alertContext.showErrorAlert(t("problem with deleting role from server"));
            });
    }, [props, role]);

    return (
        <Fragment>
            <td>{role.type}</td>
            <td>{role.description}</td>
            <td><RightsDisplay value={role.rights} /></td>
            <td><DateTimeInTimezone utcDateTime={role.updateTime} /></td>
            <IfGranted expected={Rights.RoleWrite} actual={authContext.userRights}>
                <td>
                    <EditIcon onClick={props.onEditClick} />
                    <DeleteIcon onClick={(_: any) => setShowDeleteConfirmationModal(true)} />
                    <DeleteConfirmationModal
                        show={showDeleteConfirmationModal}
                        heading={t("delete role")}
                        body={t("do you want to delete role?")}
                        onCancelButtonClicked={(_: any) => setShowDeleteConfirmationModal(false)}
                        onDeleteButtonClicked={handleDeleteButtonClicked}
                    />
                </td>
            </IfGranted>
        </Fragment>
    );
}

export default RoleRow;
