import Role from './interfaces';
import accessControlManagementApiClient from '../../../apiClient/AccessControlManagementClient';
import { AbstractCacheInstance } from '../../../utils/AbstractCacheInstance';

class RolesCache extends AbstractCacheInstance<Role> {

    async reload(
        setLoadingComponent: (value: boolean) => void,
        forceReload: boolean = false
    ): Promise<Array<Role>> {
        return await this.get().reload(
            accessControlManagementApiClient,
            accessControlManagementApiClient.getRoles,
            setLoadingComponent,
            forceReload
        );
    }

    getRoleByRoleId(userId: string): Role | undefined {
        return this.get().getById(userId);
    }

    getRoleTypeByRoleId(roleId: string, t: (message: string) => string): string {
        let role = this.getRoleByRoleId(roleId);
        if (role === undefined) {
            return t('role not found');
        }
        return role.type;
    }
}

export default new RolesCache();
