import React, { Fragment, useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';
import EditIcon from '../../../components/Icons/EditIcon';
import ProjectsSdkConfig from './interfaces';
import AutoTrackingModeDisplay from './AutoTrackingModeDisplay';
import DateTimeInTimezone from '../../../utils/DatetimeTz'
import { ProjectByProjectId, getProjectType, getUserId, getProjectName } from '../Projects/ProjectByProjectId';
import { IfGranted } from 'react-authorization'
import { Rights } from '../../../auth/Rights';
import { checkIsWebTypeProject, getAutoTrackingOptions } from '../../utils';
import { useTranslation } from 'react-i18next';
import '../../../styles/WrapAnywhere.css';

interface ProjectSdkRowProps {
    sdkConfig: ProjectsSdkConfig,
    onEditClick: (event: any) => void,
}

const ProjectSdkRow = (props: ProjectSdkRowProps) => {
    const authContext = useContext(AuthContext);

    const { t } = useTranslation();

    const sdkConfig = props.sdkConfig;

    const isWebProject = checkIsWebTypeProject(sdkConfig.projectId, t);
    const notApplicableCell = <td style={{ backgroundColor: "#d3d3d3" }}>N/A</td>;
    return (
        <Fragment>
            <td className="wrap-anywhere">{sdkConfig.projectId}</td>
            <td><ProjectByProjectId projectId={sdkConfig.projectId} getAttrFunc={getProjectName} /></td>
            <td><ProjectByProjectId projectId={sdkConfig.projectId} getAttrFunc={getProjectType} /></td>
            <td className="wrap-anywhere"><ProjectByProjectId projectId={sdkConfig.projectId} getAttrFunc={getUserId} /></td>
            <td><AutoTrackingModeDisplay value={sdkConfig.autoTrackMode} autoTrackingOptions={getAutoTrackingOptions(sdkConfig.projectId, t)} /></td>
            {notApplicableCell}
            {isWebProject ? notApplicableCell : <td>{sdkConfig.flushInterval}</td>}
            {isWebProject ? notApplicableCell : <td>{sdkConfig.flushBulkSize}</td>}
            {isWebProject ? notApplicableCell : <td>{sdkConfig.singleFlushSize}</td>}
            {isWebProject ? notApplicableCell : <td>{sdkConfig.maxCacheSize}</td>}
            <td><DateTimeInTimezone utcDateTime={sdkConfig.updateTime} /></td>
            <td>{sdkConfig.disableSDK ? "disabled" : "enabled"}</td>
            <IfGranted expected={Rights.SdkWrite} actual={authContext.userRights}>
                <td>
                    <EditIcon onClick={props.onEditClick} />
                </td>
            </IfGranted>
        </Fragment>
    );
}

export default ProjectSdkRow;
