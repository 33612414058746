import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Locale from './Locale'
import { Rights } from '../../auth/Rights';
import { IfAnyGranted, IfGranted } from 'react-authorization'
import AuthorizedNavDropdownItem from '../../components/Navigation/AuthorizedNavDropdownItem';

const Toolbar = () => {

    const { t } = useTranslation();

    const authContext = useContext(AuthContext);

    return (
        <Navbar bg="dark" variant="dark" expand="lg">
            <Navbar.Brand to="/" as={NavLink}>
                {t('eagle lab big data portal')}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                {authContext.isAuthenticated ?
                    <Nav className="mr-auto" >
                        <IfGranted expected={Rights.MainDashboardRead} actual={authContext.userRights}>
                            <Nav.Link as={NavLink} to="/dashboard">{t('dashboard')}</Nav.Link>
                        </IfGranted>
                        <IfAnyGranted expected={[Rights.AnalysisFunnelRead, Rights.AnalysisUserBehaviorRead, Rights.AnalysisQueryRead]} actual={authContext.userRights}>
                            <NavDropdown title={t('analysis')} id="basic-nav-dropdown">
                                <AuthorizedNavDropdownItem title={t('funnel')} toPath="/analysis/funnel" expectedRights={[Rights.AnalysisFunnelRead]} dataTestId="analysis-funnel-navlink" />
                                <AuthorizedNavDropdownItem title={t('behavior')} toPath="/analysis/behavior" expectedRights={[Rights.AnalysisUserBehaviorRead]} dataTestId="analysis-behavior-navlink" />
                                <AuthorizedNavDropdownItem title={t('ad-hoc query')} toPath="/analysis/query" expectedRights={[Rights.AnalysisQueryRead]} dataTestId="analysis-query-navlink" />
                            </NavDropdown>
                        </IfAnyGranted>
                        <IfAnyGranted expected={[Rights.ProjectRead, Rights.ProjectWrite, Rights.SdkRead, Rights.SdkWrite, Rights.LogQueryRead, Rights.EventDefinitionRead]} actual={authContext.userRights}>
                            <NavDropdown title={t('data ingestion')} id="basic-nav-dropdown">
                                <AuthorizedNavDropdownItem title={t('projects')} toPath="/ingestion/projects" expectedRights={[Rights.ProjectRead, Rights.ProjectWrite]} dataTestId="projects-navlink" />
                                <AuthorizedNavDropdownItem title={t('event definition')} toPath="/ingestion/metadata" expectedRights={[Rights.EventDefinitionRead]} dataTestId="event-definition-navlink" />
                                <AuthorizedNavDropdownItem title={t('sdk config')} toPath="/ingestion/sdk/config" expectedRights={[Rights.SdkRead, Rights.SdkWrite]} dataTestId="sdk-config" />
                                <AuthorizedNavDropdownItem title={t('log query')} toPath="/ingestion/log" expectedRights={[Rights.LogQueryRead]} dataTestId="log-query-navlink" />
                            </NavDropdown>
                        </IfAnyGranted>
                        <IfAnyGranted expected={[Rights.UserRead, Rights.UserWrite, Rights.RoleRead, Rights.RoleWrite]} actual={authContext.userRights}>
                            <NavDropdown title={t('access management')} id="basic-nav-dropdown">
                                <AuthorizedNavDropdownItem title={t('users')} toPath="/access/users" expectedRights={[Rights.UserRead, Rights.UserWrite]} dataTestId="users-navlink" />
                                <AuthorizedNavDropdownItem title={t('roles')} toPath="/access/role" expectedRights={[Rights.RoleRead, Rights.RoleWrite]} dataTestId="roles-navlink" />
                            </NavDropdown>
                        </IfAnyGranted>
                    </Nav>
                    :
                    <Nav className="mr-auto" />
                }
                <Nav>
                    {authContext.isAuthenticated
                        ?
                        <NavDropdown title={authContext.user} id="basic-nav-dropdown" className="dropleft" data-testid="logged-in-username">
                            <NavDropdown.Item as={NavLink} to="/logout">{t('log out')}</NavDropdown.Item>
                        </NavDropdown>
                        :
                        <React.Fragment>
                            <Nav.Link as={NavLink} to="/login" data-testid="login-navlink">{t('log in')}</Nav.Link>
                        </React.Fragment>
                    }
                </Nav>
                <Locale />
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Toolbar;
