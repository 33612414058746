import { GenericPageResult as PageResult, HasId } from '../apiClient/TrackingConfigurationClient';
import config from '../config/config';

export class Cache<T extends HasId> {
    private PAGINATION_OFFSET = 3;
    private INITIAL_PAGE_SIZE = 1000;
    private MAX_CACHE_RETRIES = 20;
    private CACHE_RETRY_INTERVAL_MS = 500;
    // NOTE: page_size is later re-set to the total number of roles in DB
    // TODO: consider introducing backend API for querying for number of projects/users/roles
    private pageSize = this.INITIAL_PAGE_SIZE;
    private cache: Map<string, T>
    private previousDataFetchTimestamp: Date
    triggerReload: boolean
    private isFirstLoad: boolean
    private isReloadingCache: boolean

    constructor() {
        this.cache = new Map<string, T>();
        this.previousDataFetchTimestamp = new Date();
        this.triggerReload = false;
        this.isReloadingCache = false;
        this.isFirstLoad = true;
        this.pageSize = this.INITIAL_PAGE_SIZE;
    }

    reload(
            client: any,
            clientFetchFunc: (page: number, page_size: number) => Promise<PageResult<T>>,
            setLoadingComponent: (value: boolean) => void,
            forceReload: boolean): Promise<Array<T>> {
        let currentTime = new Date();
        let isShouldReloadCache = this.shouldReloadCache(this.previousDataFetchTimestamp, currentTime);
        return new Promise<Array<T>>((resolve, reject) => {
            if (this.shouldReload(forceReload, isShouldReloadCache)) {
                this.isReloadingCache = true;
                clientFetchFunc.call(client, 1, this.pageSize)
                    .then((response) => {
                        this.previousDataFetchTimestamp = currentTime;
                        if (response.total_count) {
                            this.pageSize = response.total_count + this.PAGINATION_OFFSET;
                        } else {
                            this.pageSize = this.PAGINATION_OFFSET;
                        }
                        this.cache.clear()
                        response.items.forEach((elem) => {
                            this.cache.set(elem.id, elem);
                        });
                        resolve(response.items);
                    })
                    .catch((reason) => {
                        reject(reason);
                    })
                    .finally(() => {
                        this.isFirstLoad = false;
                        this.isReloadingCache = false;
                        setLoadingComponent(false);
                    })
            }
            else if(!this.isReloadingCache) {
                setLoadingComponent(false);
                resolve(Array.from(this.cache.values()));
            }
            else {
                /* It is possible that we receive many requests to reload cache at the same time.

                   For example, when displaying SDK Configs, every Project name, Project type and UserID
                   is fetched separately from projectsCache or from usersCache.

                   In this case only the first request actually does the reload, but for all the other
                   requests to resolve with the reloaded data, we have to wait here for the first
                   request to do the job. Alternatively we could wait for `this.isReloadingCache` to be false. */

                var retries = 0
                var checkIntervalId = setInterval(() => {
                    let items = Array.from(this.cache.values())
                    if (retries >= this.MAX_CACHE_RETRIES) {
                        setLoadingComponent(false)
                        clearInterval(checkIntervalId)
                        reject("Exceeded maximum number of retries on getting data from cache")
                    } else if (items.length || !this.isReloadingCache) {
                        setLoadingComponent(false);
                        clearInterval(checkIntervalId)
                        resolve(items)
                    }
                    retries++
                }, this.CACHE_RETRY_INTERVAL_MS)

            }
        });
    }

    getById(id: string): T|undefined {
        return this.cache.get(id);
    }

    getCachedElements(): Array<T> {
        return Array.from(this.cache.values());
    }

    reset(): void {
        this.cache = new Map<string, T>();
        this.isFirstLoad = true;
        this.isReloadingCache = false;
        this.pageSize = this.INITIAL_PAGE_SIZE;
    }

    private shouldReload(forceReload: boolean, isShouldReloadCache: boolean): boolean {
        return forceReload
                || this.shouldLoadFirstTime()
                || this.shouldLoadSubsequentTime(isShouldReloadCache);
    }

    private shouldReloadCache(prevTimestamp: Date, curTimestamp: Date): boolean {
        let diff: number = curTimestamp.getTime() - prevTimestamp.getTime();
        return diff > config.cache.MIN_REFRESH_INTERVAL_MSEC;
    }

    private shouldLoadFirstTime(): boolean {
        return this.isFirstLoad && !this.isReloadingCache;
    }

    private shouldLoadSubsequentTime(shouldReloadCache: boolean): boolean {
        return shouldReloadCache && !this.isReloadingCache;
    }
}
