import { HasId } from '../apiClient/TrackingConfigurationClient';
import { Cache } from './Cache';

export interface CacheInstance<T extends HasId> {
    get(): Cache<T>
    reload(
        setLoadingComponent: (value: boolean) => void,
        forceReload: boolean
    ): Promise<Array<T>>
    reset(): void
    getObjects(): Array<T>
    getObjectById(id: string): T|undefined
}

export abstract class AbstractCacheInstance<T extends HasId> implements CacheInstance<T> {
    private cache: Cache<T>;

    constructor() {
        this.cache = new Cache<T>();
    }

    abstract reload(
            setLoadingComponent: (value: boolean) => void,
            forceReload: boolean
    ): Promise<Array<T>>;

    get(): Cache<T> {
        return this.cache;
    }

    getObjects(): Array<T> {
        return this.cache.getCachedElements();
    }

    getObjectById(id: string): T|undefined {
        return this.cache.getById(id)
    }

    reset() {
        this.cache.reset();
    }
}
