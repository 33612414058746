import trackingConfigurationApiClient from '../../../apiClient/TrackingConfigurationClient';
import Project from './interfaces';
import { AbstractCacheInstance } from '../../../utils/AbstractCacheInstance';

class ProjectsCache extends AbstractCacheInstance<Project> {

    async reload(
        setLoadingComponent: (value: boolean) => void,
        forceReload: boolean = false
    ): Promise<Array<Project>> {
        return await this.get().reload(
            trackingConfigurationApiClient,
            trackingConfigurationApiClient.getProjectsBaseInformation,
            setLoadingComponent,
            forceReload
        );
    }

    getObjectToLabelFunction(idAsLabel: boolean, attr?: string): (obj: any) => string {
        return function (project: Project) {
            return idAsLabel ? project.id : project.name;
        }
    }

    static getLabel(object: Project, idAsLabel: boolean): string {
        return idAsLabel ? object.id : object.name;
    }

    filterByName(inputValue: String): Array<Project> {
        return this.get().getCachedElements().filter(
            (obj: Project) => obj.name.toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase())
        );
    }

    filterByAttribute(inputValue: String, attr?: string): Array<Project> {
        throw new Error(`Cannot filter by attribute ${attr}`);
    }

    getProjectNameByProjectId(projectId: string, t: (msg: string) => string) {
        let project = this.getObjectById(projectId);
        if (project === undefined) {
            return t("project not found");
        }
        return project.name;
    }

    getProjectTypeByProjectId(projectId: string, t: (msg: string) => string) {
        let project = this.getObjectById(projectId);
        if (project === undefined) {
            return t("project not found");
        }
        return project.projectType;
    }

    getUserIdByProjectId(projectId: string, t: (msg: string) => string) {
        let project = this.getObjectById(projectId);
        if (project === undefined) {
            return t("project not found");
        }
        return project.userId;
    }
}

export default new ProjectsCache();
