import React, { useState } from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

export type NullableMoment = moment.Moment | null;

export interface DateRange {
    startDate: NullableMoment,
    endDate: NullableMoment,
};

export interface DatePickerProps  {
    startDate: NullableMoment,
    startDateId?: string,
    endDate: NullableMoment,
    endDateId?: string,
    onDatesChange: (dateRange: DateRange) => void,
    isOutsideRange?: () => boolean,
    dateFormat?: string,
};

export const DatePicker = (props: DatePickerProps) => {
    const [dateFocusedInput, setDateFocusedInput] = useState<FocusedInputShape|null>(null);
    const [initialMonth, setInitialMonth] = useState<moment.Moment>(moment().subtract(1, 'month'));

    return <DateRangePicker
        startDate={props.startDate} 
        startDateId={props?.startDateId || "dateRangeStartDateId"} 
        endDate={props.endDate} 
        endDateId={props?.startDateId || "dateRangeEndDateId"} 
        onDatesChange={(dateRange: DateRange) => {
            props.onDatesChange(dateRange); 
            const initialDate = dateRange.startDate || moment().subtract(1, 'month');
            setInitialMonth(initialDate);
        }} 
        isOutsideRange={props?.isOutsideRange || (() => false)}
        focusedInput={dateFocusedInput} 
        onFocusChange={(focusedInput) => setDateFocusedInput(focusedInput)}
        minimumNights={0}
        initialVisibleMonth={ () => initialMonth}
        displayFormat={props?.dateFormat}
    />
};