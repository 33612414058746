import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Spinner from '../../components/UI/Spinner';

const Logout = (props: any) => {
    const authContext = useContext(AuthContext);

    useEffect(() => {
        if(!authContext.autoSignUpInProgress) {
            authContext.logout(() => {
                props.history.push('/login');
            });
        }
    }, [authContext, authContext.autoSignUpInProgress, props.history]);
    
    if(authContext.authIsLoading) {
        return <Spinner />
    }
    else {
        return null;
    }
}
 
export default Logout;