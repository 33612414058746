import React from 'react';
import { Spinner } from 'react-bootstrap';
import ReactPlaceholder from 'react-placeholder';
import "react-placeholder/lib/reactPlaceholder.css";

export interface PlotIconProps {
    fill: string,
    showLoadingAnimation: boolean,
}

export const PlotIcon = (props: PlotIconProps) => (
    <div className="no-borderRadiusImportant" style={{
        border: `5px solid ${props.fill}`,
        borderRadius: '10px', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        animation: props.showLoadingAnimation ? 'react-placeholder-pulse 1.5s infinite' : '',
    }}>
        { props.showLoadingAnimation ? <Spinner animation="border" variant="secondary" style={{position: 'absolute'}}/> : ''}
        <svg width="300" height="300" xmlns="http://www.w3.org/2000/svg" style={{margin: '0 auto', display: 'block'}}>
            <g>
                <title>Layer 1</title>
            </g>
            <g>
                <title>background</title>
                <g display="none" overflow="visible" y="0" x="0" height="100%" width="100%" id="canvasGrid">
                    <rect fill="url(#gridpattern)" y="0" x="0" height="100%" width="100%" style={{strokeWidth: '0'}}/>
                </g>
                <line stroke={props.fill} id="svg_2" y2="229.45313" x2="292.50146" y1="229.45313" x1="12.50005" style={{strokeWidth: '1.5'}} fill="none"/>
                <rect stroke={props.fill} id="svg_3" height="109" width="32" y="119.75138" x="46.00007" style={{strokeWidth: '1.5'}} fill={props.fill}/>
                <rect stroke={props.fill} id="svg_7" height="85.00048" width="32" y="143.45265" x="107.00011" style={{strokeWidth: '1.5'}} fill={props.fill}/>
                <rect stroke={props.fill} id="svg_8" height="138.49942" width="32" y="90.95367" x="169.99887" style={{strokeWidth: '1.5'}} fill={props.fill}/>
                <rect stroke={props.fill} id="svg_9" height="191.99838" width="32" y="36.45474" x="229.99769" style={{strokeWidth: '1.5'}} fill={props.fill}/>
                <line stroke={props.fill} id="svg_10" y2="80.72792" x2="80.25138" y1="134.72686" x1="16.75263" style={{strokeWidth: '9'}} fill="none"/>
                <line stroke={props.fill} id="svg_12" y2="125.22705" x2="125.75049" y1="80.72792" x1="74.75149" style={{strokeWidth: '9'}} fill="none"/>
                <line stroke={props.fill} id="svg_14" y2="14.72922" x2="227.74849" y1="125.22705" x1="119.75061" style={{strokeWidth: '9'}}  fill="none"/>
                <path id="svg_16" d="m-251.79112,-113.06061l0.23624,-0.23705l-0.45676,0l-0.45675,0l0,-0.14674l0,-0.14674l0.45151,0c0.24833,0 0.45151,-0.00744 0.45151,-0.01653c0,-0.00909 -0.09886,-0.11576 -0.21969,-0.23705l-0.21969,-0.22052l0.20306,0l0.20306,0l0.31012,0.31071l0.31012,0.31071l-0.31071,0.31012l-0.31071,0.31012l-0.21377,0l-0.21377,0l0.23624,-0.23705l0,0z" style={{strokeWidth: '1.5'}} stroke="#000" fill={props.fill}/>
            </g>
        </svg>
    </div>
);

export interface PlaceholderProps  {
    children: React.ReactNode,
    showLoadingAnimation: boolean,
    type?: "rect" | "text" | "media" | "round" | "textRow" | undefined,
    ready: boolean,
    rows?: number,
    style?: React.CSSProperties, 
}

export const Placeholder = (props: PlaceholderProps) => {
    if (props.type === undefined)
    return <ReactPlaceholder 
                showLoadingAnimation={props.showLoadingAnimation} 
                ready={ props.ready } 
                customPlaceholder={ <PlotIcon fill="#D3D3D3" showLoadingAnimation={props.showLoadingAnimation}/> }
                style={ props.style }
            >
                { props.children }
            </ReactPlaceholder>
    else return <ReactPlaceholder 
            showLoadingAnimation={props.showLoadingAnimation} 
            type={ props.type }
            ready={ props.ready } 
            rows={ props?.rows || 10 }
            style={ props.style }
        >
            { props.children }
        </ReactPlaceholder>
}