import React, { Fragment, useState, useCallback, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import User from './interfaces';
import accessControlManagementApiClient from '../../../apiClient/AccessControlManagementClient';
import SmallErrorMessage from '../../../components/Forms/SmallErrorMessage';
import { AlertContext } from '../../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import SelectSearch from '../../../components/Forms/SelectSearch';
import rolesCache from '../Roles/rolesCache';
import Role from '../Roles/interfaces';
import CancelSaveButtons from '../../../components/Forms/CancelSaveButtons';


interface EditUserFormProps {
    user: User,
    onCancelClick: (event: any) => void,
    onUserEdited: (user: User) => void,
}

function EditUserForm(props: EditUserFormProps) {
    const user = props.user;

    const alertContext = useContext(AlertContext);

    const [name, setName] = useState(user.name);
    const [description, setDescription] = useState(user.description);
    const [roleId, setRoleId] = useState(user.roleId);

    const [nameIsInvalid, setNameIsInvalid] = useState(false);
    const [descriptionIsInvalid, setDescriptionIsInvalid] = useState(false);
    const [roleIdIsInvalid, setRoleIdIsInvalid] = useState(false);

    const { t } = useTranslation();

    let configNotChanged = false;

    if (user.name === name
        && user.description === description
        && user.roleId === roleId) {
        configNotChanged = true;
    }

    const handleSaveButtonClick = useCallback((event: any) => {

        event.preventDefault();

        let formIsValid = true;

        if (name.trim() === '') {
            setNameIsInvalid(true);
            formIsValid = false;
        }
        else {
            setNameIsInvalid(false);
        }

        if (description.trim() === '') {
            setDescriptionIsInvalid(true);
            formIsValid = false;
        }
        else {
            setDescriptionIsInvalid(false);
        }

        if (roleId.trim() === '') {
            setRoleIdIsInvalid(true);
            formIsValid = false;
        }
        else {
            setRoleIdIsInvalid(false);
        }

        if (formIsValid) {
            const updateUserRequest = {
                name: name,
                email: user.email,
                description: description,
                roleId: roleId
            };
            accessControlManagementApiClient.updateUser(user.id, updateUserRequest)
                .then((response: User) => {
                    props.onUserEdited(response);
                    alertContext.showSuccessAlert(t("updated user", { name: user.name }));
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(reason.message);
                });
        }
        else {
            alertContext.showErrorAlert(t("provide all mandatory fields"));
        }
    }, [props, user, name, description, roleId]);

    return (
        <Fragment>
            <td>
                <Form.Control
                    type="email"
                    placeholder={t("enter user name")}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                />
                <SmallErrorMessage show={nameIsInvalid} message={t("user name can't be empty")} />
            </td>
            <td>
                <Form.Control
                    readOnly
                    defaultValue={user.id}
                />
            </td>
            <td>
                <Form.Control
                    readOnly
                    defaultValue={user.email}
                />
            </td>
            <td>
                <Form.Control
                    type="text"
                    placeholder={t("enter user description")}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    autoComplete="off"
                />
                <SmallErrorMessage show={descriptionIsInvalid} message={t("description can't be empty")} />
            </td>
            <td width="200px">
                <SelectSearch<Role>
                    value={ roleId }
                    onChange={ setRoleId }
                    placeholder={ t('enter role type') }
                    cache={rolesCache}
                    classNamePrefix="role"
                    filterByAttribute={(inputValue: String, objects: Array<Role>) => {
                        return objects.filter((obj: Role) => obj.type.toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase()))
                    }}
                    getLabel={(role: Role) => role.type}
                />
                <SmallErrorMessage show={roleIdIsInvalid} message={t("role id can't be empty")} />
            </td>
            <td>
                <Form.Control
                    readOnly
                    defaultValue={user.updateTime}
                />
            </td>
            <td>
                <CancelSaveButtons
                    onCancelClick={props.onCancelClick}
                    onSaveClick={handleSaveButtonClick}
                    configNotChanged={configNotChanged}
                />
            </td>
        </Fragment>
    );
}

export default EditUserForm;
