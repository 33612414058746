import React, { useState, useContext } from 'react';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { AuthContext } from '../../context/AuthContext';
import { AlertContext } from '../../context/AlertContext';
import Spinner from '../../components/UI/Spinner';
import { useTranslation } from 'react-i18next';

const Login = (props: any) => {
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');

    const [usernameIsInvalid, setUsernameIsInvalid] = useState(false);
    const [passwordIsInvalid, setPasswordIsInvalid] = useState(false);

    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);

    const { t } = useTranslation();

    const handleLogin = (event: any) => {
        event.preventDefault();

        let formIsValid = true;

        if(userName.trim() === '') {
            setUsernameIsInvalid(true);
            formIsValid = false;
        }
        else {
            setUsernameIsInvalid(false);
        }

        if(password.trim() === '') {
            setPasswordIsInvalid(true);
            formIsValid = false;
        }
        else {
            setPasswordIsInvalid(false);
        }

        if(formIsValid) {
            authContext.login(userName, password, () => {
                alertContext.showSuccessAlert(t('login success'));
                props.history.push('/dashboard');
            }, () => {
                alertContext.showErrorAlert(t('invalid user or password'));
            });
        }
        else {
            alertContext.showErrorAlert(t("user and password can't be empty"));
        }
    }

    let usernameClassName = '';
    if(usernameIsInvalid) {
        usernameClassName = usernameClassName + ' is-invalid';
    }
    let passwordClassName = '';
    if(passwordIsInvalid) {
        passwordClassName = passwordClassName + ' is-invalid';
    }

    if(authContext.authIsLoading) {
        return <Spinner />
    }

    return (
        <Container>
            <Card className="mx-auto m-5" style={{'maxWidth': '25rem'}}>
                <Card.Header>{t('welcome')}</Card.Header>
                <Card.Body>
                    <Form data-testid="login-form">
                        <Form.Group controlId="username">
                            <Form.Control
                                className={ usernameClassName }
                                type="email"
                                name="email"
                                placeholder={t('user name')}
                                value={ userName }
                                onChange={(event) => setUserName(event.target.value) }
                            />
                            { usernameIsInvalid ? <div className="invalid-feedback" data-testid="invalid-username-msg">{t("username can't be empty")}</div> : null }
                        </Form.Group>

                        <Form.Group controlId="password">
                            <Form.Control
                                className={ passwordClassName }
                                type="password"
                                placeholder={t('password')}
                                value={ password }
                                onChange={(event) => setPassword(event.target.value) }
                            />
                            { passwordIsInvalid ? <div className="invalid-feedback" data-testid="invalid-password-msg">{t("password can't be empty")}</div> : null }
                        </Form.Group>
                        <Button variant="primary" type="submit" onClick={ handleLogin } data-testid="login-button" block>{t('login')}</Button>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}

export default Login;
