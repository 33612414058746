import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ButtonVariant } from 'react-bootstrap/types';

interface ModalButtonProps {
    text: string,
    variant: ButtonVariant,
    onClick: (event: any) => void,
}

interface ConfirmationModalProps {
    show: boolean,
    heading: string,
    body: any,
    firstButton: ModalButtonProps,
    secondButton: ModalButtonProps,
}

/*
    Please note that there is open issue in react-bootstrap library: https://github.com/react-bootstrap/react-bootstrap/issues/5075
    which generates warning: findDOMNode is deprecated in StrictMode. findDOMNode was passed an instance of Transition which is inside StrictMode [...]
*/
const ConfirmationModal = (props: ConfirmationModalProps) => (
    <Modal show={props.show}>
        <Modal.Header>
            <Modal.Title>{props.heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>
            <Button variant={props.firstButton.variant} onClick={props.firstButton.onClick}>
            {props.firstButton.text}
            </Button>
            <Button variant={props.secondButton.variant} onClick={props.secondButton.onClick}>
            {props.secondButton.text}
            </Button>
        </Modal.Footer>
    </Modal>
);

export default ConfirmationModal;
