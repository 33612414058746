import React, { Fragment, useCallback, useContext, useState } from 'react';
import projectsCache from '../Projects/projectsCache';
import SelectSearch from '../../../components/Forms/SelectSearch';
import Project from '../Projects/interfaces';
import { Col, Row, Button, Form, Badge } from 'react-bootstrap';
import ReactJson from 'react-json-view'
import Spinner from '../../../components/UI/Spinner';
import userBehaviorDataApiClient from '../../../apiClient/UserBehaviorDataClient';
import { AlertContext } from '../../../context/AlertContext';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { IfGranted } from 'react-authorization'
import { Rights } from '../../../auth/Rights';

const LogQuery = () => {

    const [projectId, setProjectId] = useState('');
    const [events, setEvents] = useState<Array<string>>([]);
    const [appVersion, setAppVersion] = useState('');
    const [userId, setUserId] = useState('');
    const [jsonData, setJsonData] = useState({})
    const [loading, setLoading] = useState(false);

    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);


    const { t } = useTranslation();

    const onSubmitButtonClick = useCallback(() => {
        setLoading(true);
        userBehaviorDataApiClient.get_raw_events(projectId, events, appVersion, userId)
            .then((response) => {
                setJsonData(response);
            })
            .catch(() => {
                alertContext.showErrorAlert(t("problem with reading log query"));
            })
            .finally(() => setLoading(false))
    }, [projectId, events, appVersion, userId]);


    let content = null;

    if (loading) {
        content = <Spinner />;
    }
    else {
        content =
            <ReactJson
                src={jsonData}
                theme="google"
                name={false}
            />
    }


    return (
        <IfGranted expected={Rights.LogQueryRead} actual={authContext.userRights}>
            <Fragment >
                <h3>
                    {t('log query')}
                </h3>
                <br />
                <Row style={{ marginTop: 5, marginBottom: 5 }}>
                    <Col lg={true} style={{
                        marginLeft: '15px',
                        marginRight: '5px'
                    }}>
                        <Row lg="1">
                            <h6>{t('select project name')} <Badge pill variant="danger">{t('required')}</Badge></h6>
                            <SelectSearch<Project>
                                value={projectId}
                                onChange={setProjectId}
                                placeholder={""}
                                showClearButton={false}
                                cache={projectsCache}
                                classNamePrefix="project"
                            />
                        </Row>
                        <Row lg="1" style={{ marginTop: '7px' }}>
                            <h6>{t('select app version')}</h6>
                            <Form.Control
                                type="text"
                                value={appVersion}
                                onChange={(event) => setAppVersion(event.target.value)}
                            ></Form.Control>
                        </Row>
                    </Col>
                    <Col lg={true} style={{
                        marginLeft: '5px',
                        marginRight: '15px'
                    }}>
                        <Row lg="1">
                            <h6>{t('provide comma separated event names')}</h6>
                            <Form.Control
                                type="text"
                                value={events}
                                data-cy="events"
                                onChange={(event) => setEvents(event.target.value.split(',').map(s => s.trim()))}
                            ></Form.Control>
                        </Row>
                        <Row lg="1" style={{ marginTop: '7px' }}>
                            <h6>{t('select user id')}</h6>
                            <Form.Control
                                type="text"
                                value={userId}
                                onChange={(event) => setUserId(event.target.value)}
                            ></Form.Control>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ marginTop: 5, marginBottom: 5 }}>
                    <Col>
                        <div style={{ marginTop: 5, float: 'right' }}>
                            {!loading ?
                                <Button data-testid='submit-button' onClick={onSubmitButtonClick} disabled={projectId === ''} data-cy={'submit-log-query-button'}>
                                    {t('submit')}
                                </Button>
                                :
                                null
                            }
                        </div>
                    </Col>
                </Row>
                <h5>{t('events')}</h5>
                {content}
            </Fragment>
        </IfGranted>
    )
}

export default LogQuery;
