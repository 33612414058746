const config = {
    api: {
        trackingConfiguration: {
            BASE_URL: process.env.REACT_APP_TRACKING_CONFIGURATION_BASE_URL as string,
        },
        accessControlManagementConfiguration: {
            BASE_URL: process.env.REACT_APP_ACCESS_MANAGEMENT_SERVICE_URL as string
        },
        userBehaviorData: {
            BASE_URL: process.env.REACT_APP_USER_BEHAVIOR_DATA_BASE_URL as string
        },
        authConfiguration: {
            BASE_URL: process.env.REACT_APP_AUTH_URL as string
        }
    },
    pagination: {
        PAGE_SIZE: 20
    },
    cache: {
        MIN_REFRESH_INTERVAL_MSEC: 3000,
    }
};

export default config;
