import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import Table from 'react-bootstrap/Table';
import '../../../styles/Table.css';
import Container from 'react-bootstrap/Container';
import { AuthContext } from '../../../context/AuthContext';
import ProjectsSdkConfig from './interfaces';
import ProjectSdkRowContainer from './ProjectSdkRowContainer';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/UI/Spinner';
import Pagination from '../../../components/UI/Pagination';
import config from '../../../config/config';
import FilterByProjectNameAndId from '../../../components/Forms/FilterByProjectNameAndId';
import projectsCache from '../Projects/projectsCache';
import { Rights } from '../../../auth/Rights';
import { IfGranted, IfAnyGranted } from 'react-authorization'
import sdkConfigCache from './sdkConfigCache';
import { byDateDescOrdering, replaceSpacesWithSoftBreaks } from '../../utils';
import { AlertContext, AlertContextInterface } from '../../../context/AlertContext';

const SdkConfiguration = () => {

    const PAGE_SIZE = config.pagination.PAGE_SIZE;

    const [sdkConfigs, setSdkConfigs] = useState<Array<ProjectsSdkConfig>>([]);

    const [projectIdByName, setProjectIdByName] = useState('');
    const [projectId, setProjectId] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [loading, setLoading] = useState(true);
    const [loadingProjects, setLoadingProjects] = useState(true);

    const authContext = useContext(AuthContext);

    const alertContext: AlertContextInterface = useContext(AlertContext);

    const { t } = useTranslation();

    const filterByProjectNameOrId = (sdkConfigs: Array<ProjectsSdkConfig>, projectIdByName?: string, projectId?: string): Array<ProjectsSdkConfig> => {
        return sdkConfigs.filter(p => p.projectId === projectId || p.projectId === projectIdByName)
    }

    const isFilteringByProjectNameOrId = (projectIdByName?: string, projectId?: string): boolean => {
        return projectIdByName !== '' || projectId !== '';
    }


    useEffect(() => {
        if (authContext.isAuthenticated) {
            sdkConfigCache.reload(setLoading)
                .then((response: Array<ProjectsSdkConfig>) => {
                    const filteredSdkConfigs = isFilteringByProjectNameOrId(projectIdByName, projectId)
                        ? filterByProjectNameOrId(response, projectIdByName, projectId)
                        : response

                    if (filteredSdkConfigs.length <= PAGE_SIZE) {
                        setCurrentPage(1);
                    }
                    setSdkConfigs(filteredSdkConfigs
                        .sort(byDateDescOrdering())
                        .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE));
                    setTotalItems(filteredSdkConfigs.length);
                    setTotalPages(Math.ceil(filteredSdkConfigs.length / PAGE_SIZE));
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(t("error in processing response from server", { error: reason }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [loading, PAGE_SIZE, currentPage, projectIdByName, projectId, authContext.isAuthenticated]);

    useEffect(() => {
        projectsCache.reload(setLoadingProjects)
            .catch((reason) => {
                alertContext.showErrorAlert(t("error in processing response from server", { error: reason }));
            })
    }, [loadingProjects]);

    const handleProjectEdited = useCallback(
        (project: ProjectsSdkConfig) => {
            const newProjects = [...sdkConfigs];
            const projectIndex = sdkConfigs.findIndex(p => p.id === project.id);
            newProjects[projectIndex] = project;
            setSdkConfigs(newProjects);
            setLoading(true);
        },
        [sdkConfigs],
    );

    const handlePageChange = useCallback((selectedPage: number) => {
        setCurrentPage(selectedPage);
    }, []);

    let content = null;

    if (loading || loadingProjects) {
        content = <Spinner />;
    }
    else {
        let table = <Table striped bordered hover responsive className="m-0 sticky-header min-width-75" size="sm">
            <thead>
                <tr>
                    <th>{t("projectID")}</th>
                    <th>{t("project name")}</th>
                    <th>{t("project type")}</th>
                    <th>{t("userID")}</th>
                    <th>{replaceSpacesWithSoftBreaks(t("autoTrackMode"))}</th>
                    <th>{t("js bridge")}</th>
                    <th>{t("flush interval")}</th>
                    <th>{t("flush bulk size")}</th>
                    <th>{t("single flush size")}</th>
                    <th>{t("max cache size")}</th>
                    <th>{t("update")}</th>
                    <th>{t("disableSDK")}</th>
                    <IfGranted expected={Rights.SdkWrite} actual={authContext.userRights}>
                        <th>{t("edit")}</th>
                    </IfGranted>
                </tr>
            </thead>
            <tbody data-testid="projects-list">
                {
                    sdkConfigs?.
                        map(config =>
                            <ProjectSdkRowContainer
                                key={config.id}
                                sdkConfig={config}
                                onProjectEdited={handleProjectEdited}
                            />
                        )
                }
            </tbody>
        </Table>

        // calculating table height by substracting combined heights of header (56), top/bottom margins (24+4), page title (33), filtering (95), pagination (optional: 58)
        let elementsHeight = 212;
        if (totalPages > 1) elementsHeight += 58;
        content = <Container fluid className="d-flex pb-3 pt-2 px-0 m-0" style={{ height: `calc(100vh - ${elementsHeight}px)` }}>
            {table}
        </Container>
    }

    return (
        <IfAnyGranted expected={[Rights.SdkRead, Rights.SdkWrite]} actual={authContext.userRights}>
            <Fragment>
                <h3>
                    {t("SDK configurations")}
                </h3>
                <FilterByProjectNameAndId projectIdByName={projectIdByName} setProjectIdByName={setProjectIdByName} projectId={projectId} setProjectId={setProjectId} />
                {/* TODO Uncomment when multiple sdk configs for one project will be required */}
                {/* <AddProjectsSdkConfigButton onProjectsSdkConfigAdded={handleProjectsSdkConfigAdded} disabled={!hasRights(authContext.userRights, Rights.SdkWrite)} /> */}
                {content}
                {
                    totalPages > 1 ?
                        <div className="d-flex justify-content-center" data-testid="pagination">
                            <Pagination
                                pageCount={totalPages}
                                itemsCount={totalItems}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        : null
                }
            </Fragment>
        </IfAnyGranted>
    );
}

export default SdkConfiguration;