import React, { useState, useCallback } from 'react';
import Role from './interfaces';
import RoleRow from './RoleRow';
import EditRoleForm from './EditRoleForm';

interface RoleRowContainerProps {
    role: Role,
    onRoleDeleted: (roleId: string) => void,
    onRoleEdited: (role: Role) => void,
}

const RoleRowContainer = (props: RoleRowContainerProps) => {
    const role = props.role;

    const [editing, setEditing] = useState(false);

    let roleDisplay;

    const handleRoleEdited = useCallback(
        (project: Role) => {
            props.onRoleEdited(project);
            setEditing(false);
        },
        [props],
    );

    if(editing) {
        roleDisplay =
            <EditRoleForm
                role={ role }
                onCancelClick={ () => setEditing(false) }
                onRoleEdited={ handleRoleEdited }
            />
    }
    else {
        roleDisplay =
            <RoleRow
                role={ role }
                onEditClick={ (_: any) => setEditing(true) }
                onRoleDeleted={ props.onRoleDeleted }
            />;
    }
    return (<tr>{ roleDisplay }</tr>);
}

export default RoleRowContainer;
