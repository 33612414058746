import CodeMirror from '@uiw/react-codemirror';
import 'codemirror/keymap/sublime';
import 'codemirror/theme/neo.css';
import '../../styles/Table.css';
import { Parser, AST, From, Limit } from 'node-sql-parser';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Accordion, Alert, Button, Card, Col, Row, Container, ListGroup, Spinner, Table, Popover, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AlertContext } from '../../context/AlertContext';
import { AuthContext } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import userBehaviorDataApiClient, { QueryResponseDTO, StartQueryResponseDTO } from '../../apiClient/UserBehaviorDataClient';
import { TableRecord } from './interfaces';
import { Rights } from '../../auth/Rights';
import { IfGranted } from 'react-authorization';
import moment from 'moment';

const DEFAULT_RESULTS_LIMIT = 100
const MAX_RESULTS_LIMIT = 1000

const defaultResultsLimit: Limit = {seperator: '', value: [{type:'number', value: DEFAULT_RESULTS_LIMIT}]}
const maxResultsLimit: Limit = {seperator: '', value: [{type:'number', value: MAX_RESULTS_LIMIT}]}

function getTableName(table: TableRecord): string {
    return table.table_name.includes('.') ? table.table_name : `${table.database_name}.${table.table_name}`
}

function getExampleTableName(tables: Array<TableRecord>): string | undefined {
    return tables.map(table => getTableName(table)).find(tableName => tableName.endsWith('tracking_events'));
}

const AdHocQuery = () => {
    const defaultExampleTableName = 'tracking.tracking_events'
    const [code, setCode] = useState<string>('SELECT * FROM table_name');
    const [queryData, setQueryData] = useState<Array<Object>>([]);
    const [tables, setTables] = useState<Array<TableRecord>>([]);
    const [exampleTable, setExampleTable] = useState<string>(defaultExampleTableName);
    const [loading, setLoading] = useState<boolean>(false);
    const [warning, setWarning] = useState<string>('');
    const [forcedResultsLimit, setForcedResultsLimit] = useState<number>(0);

    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);

    const { t } = useTranslation();

    const currentMonth = moment().format('MM');
    const currentYear = moment().format('YYYY');
    const currentDay = moment().format('DD');

    useEffect(() => {
        if (authContext.isAuthenticated) {
            userBehaviorDataApiClient.listTables()
                .then((response: Array<TableRecord>) => {
                    setTables(response);
                    setExampleTable(getExampleTableName(response) || defaultExampleTableName);
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(t('problem with reading tables from server'));
                });
        }
    }, [authContext.isAuthenticated]);

    const getAthenaQueryResult = useCallback((queryId: string) => {
        let timers: NodeJS.Timeout[] = [];
        const maxRetries = 60; //Wait maximum 3 minutes for query result
        for (let counter = 0; counter <= maxRetries; counter++) {
            (function (ind) {
                timers.push(setTimeout(function () {
                    let result: QueryResponseDTO;
                    userBehaviorDataApiClient.getAthenaQueryResult(queryId)
                        .then((response) => {
                            result = response;
                            if (result.data.length === 0 && result.status_code === 200) {
                                alertContext.showErrorAlert(t("query returned zero records"));
                            }
                            setQueryData(result.data);
                        })
                        .catch(() => {
                            alertContext.showErrorAlert(t("problem with reading query"));
                            setLoading(false);
                            timers.forEach(timer => clearTimeout(timer));
                            result = { data: [], status_code: 500 }
                        })
                        .finally(() => {
                            if (result.status_code === 204 && counter !== maxRetries) {
                                setLoading(true);
                            }
                            else {
                                timers.forEach(timer => clearTimeout(timer));
                                setLoading(false);
                                if (result.status_code === 204) {
                                    alertContext.showErrorAlert(t("query timed out"));
                                }
                            }
                        });
                }, 1000 + (3000 * ind)));
            })(counter);
        }
    }, []);

    const onSubmitButtonClick = useCallback((event) => {
        try {
            setLoading(true);
            const parser = new Parser();
            const strippedQuery = code.replace(/;+$/g, '');
            const parseAst: AST | AST[] = parser.astify(strippedQuery);
            if (Array.isArray(parseAst))
                throw new Error(t('only single query is permitted'))

            const ast = parseAst as AST;
            if (ast.type !== 'select')
                throw new Error(t('only select query is permitted'))

            if (ast.from === null)
                throw new Error(t('from statement missing'))

            const tableNames = new Map(tables.map(table => [getTableName(table), table.database_type]));

            const databaseTypes: Set<string> = new Set();
            ast.from?.forEach(value => {
                const frm = value as From;
                const tableName = (frm.db !== null) ? `${frm.db}.${frm.table}` : frm.table;
                const databaseType = tableNames.get(tableName);
                if (databaseType === undefined)
                    throw new Error(t('from statement contains unknown table'));
                databaseTypes.add(databaseType);
            });

            if (databaseTypes.size !== 1)
                throw new Error(t('from statement contains more than one database'));

            const databaseType = Array.from(databaseTypes)[0];

            const query = parser.sqlify(ast);

            if (ast.limit === null) {
                ast['limit'] = defaultResultsLimit
                setForcedResultsLimit(DEFAULT_RESULTS_LIMIT)
                setWarning(t('you didnt set limit', { defaultLimit: DEFAULT_RESULTS_LIMIT }))
            } else if (ast.limit?.value[0].value > MAX_RESULTS_LIMIT) {
                ast.limit = maxResultsLimit
                setForcedResultsLimit(MAX_RESULTS_LIMIT)
                setWarning(t('limit was too high', { maxLimit: MAX_RESULTS_LIMIT}))
            } else {
                setForcedResultsLimit(0)
                setWarning('')
            }

            if (databaseType === 'redshift') {
                userBehaviorDataApiClient.postQuery(query)
                    .then((response: Array<Object>) => {
                        if (response.length === 0) {
                            alertContext.showErrorAlert(t("query returned zero records"))
                        }
                        setQueryData(response);
                    })
                    .catch((reason) => {
                        alertContext.showErrorAlert(reason.message);
                    }).finally(() => setLoading(false));
            } else if (databaseType === 'athena') {
                userBehaviorDataApiClient.startAthenaQuery(query)
                    .then((response: StartQueryResponseDTO) => {
                        getAthenaQueryResult(response.athena_query_id);
                    })
                    .catch((reason) => {
                        alertContext.showErrorAlert(reason.message);
                        setLoading(false);
                    });
            } else
                throw new Error(t('from statement contains unknown database type'));


        } catch (error) {
            alertContext.showErrorAlert(t(error.message));
            setLoading(false);
        }
    }, [code, tables, getAthenaQueryResult]);

    // calculating table height by substracting combined heights of header (56), top/bottom margins (24+4), page title (33), query row + margin (56+16) + warning (optional)
    let elementsHeight = 189 + warning!=='' ? 50 : 0;

    let table = null;
    if (queryData?.length > 0) {
        table = <Table striped bordered hover responsive className="m-0 sticky-header min-width-75" size="sm">
            <thead data-testid="query-table">
                <tr>
                    {Object.keys(queryData[0]).map((key) => <th key={key}>{key}</th>)}
                </tr>
            </thead>
            <tbody data-testid="query-data-list">
                {
                    queryData.map((row, index) => (
                        <tr key={`query-data-list-row-${index}`}>
                            { Object.values(row).map((value, valueIndex) => <td key={`td-${index}-${valueIndex}`}>{value?.toString()}</td>)}
                        </tr>
                    ))
                }
            </tbody>
        </Table>
    }

    const helpPopover = (
        <Popover id="help-popover" style={{ maxWidth: 500 }}>
            <Popover.Title as="h3">{t("tips for improving query performance")}</Popover.Title>
            <Popover.Content>
                <ul>
                    <li>{t("limit the number of results", { defaultLimit: DEFAULT_RESULTS_LIMIT, maxLimit: MAX_RESULTS_LIMIT })}: <code>SELECT * FROM {exampleTable} <strong>LIMIT 20</strong></code></li>
                    <li>{t("select only necessary columns")}: <code>SELECT <strong>project, app_name, event</strong> FROM {exampleTable}</code></li>
                    <li>{t("use where to reduce number of results")}: <code>SELECT * FROM {exampleTable} <strong>WHERE year='{currentYear}' and month='{currentMonth}' and day='{currentDay}'</strong></code></li>
                    <li>{t("use limit when using order by")}: <code>SELECT * FROM {exampleTable} WHERE year='{currentYear}' and month='{currentMonth}' <strong>ORDER BY time LIMIT 10</strong></code></li>
                    <li>{t("when using group by, order the columns by the highest cardinality")}: <code>SELECT app_name, app_version, count(*) FROM {exampleTable} GROUP BY <strong>app_version, app_name</strong></code></li>
                </ul>
            </Popover.Content>
        </Popover>
    );

    const warningAlert = warning !== '' && queryData?.length === forcedResultsLimit ? (<Container className='m-0 p-0'><Alert className='d-flex my-1 align-items-center' variant='warning'><FontAwesomeIcon icon={['fas', 'exclamation-triangle']} className="alert-warning" /><span>{warning}</span></Alert></Container>) : null;

    return (
        <IfGranted expected={Rights.AnalysisQueryRead} actual={authContext.userRights}>
            <Fragment>
                <h3>
                    {t('ad-hoc query')}
                </h3>
                <br />
                <Row>
                    <Col lg={2} data-testid="query-table-list">
                        {t('tables')}:
                        {tables.length < 1 ? <Spinner animation="border" variant="primary" size="sm" style={{ marginLeft: 5 }} /> : null}
                        <Accordion defaultActiveKey="0" >
                            {tables?.map((table, index) => (
                                <Card key={`query-table-card-${index}`}>
                                    <Accordion.Toggle as={Card.Header} eventKey={`query-table-list-collapse-${index}`}>
                                        {getTableName(table)}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={`query-table-list-collapse-${index}`}>
                                        <Card.Body>
                                            <ListGroup variant="flush">
                                                {table.columns.map((column, columnIndex) => (
                                                    <ListGroup.Item key={`query-table-list-item-${columnIndex}`}>
                                                        {column.column_name}: {column.column_type}
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    </Col>
                    <Col lg={10}>
                        <Row>
                            <Col>
                                <Row>
                                    <Col className="border border-dark" style={{ minHeight: 56 }} data-testid="query-editor">
                                        <CodeMirror
                                            value={code}
                                            onChange={(instance, change) => (setCode(instance.getValue()))}
                                            options={{
                                                theme: 'neo',
                                                keyMap: 'sublime',
                                                mode: 'sql',
                                                lineNumbers: false,
                                            }}
                                        />
                                    </Col>
                                    <Col md="auto">
                                        <Button onClick={onSubmitButtonClick} disabled={loading} id="submit">
                                            {loading ? <Spinner animation="border" variant="light" size="sm" className="mx-1"></Spinner> : null}
                                            {t('submit')}
                                        </Button>
                                        <OverlayTrigger trigger="click" rootClose placement="left" overlay={helpPopover}>
                                            <Button style={{ marginLeft: 5 }} data-cy="help-popover-button">
                                                <FontAwesomeIcon icon={['fas', 'question-circle']} color="white" />
                                            </Button>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <Row>
                                    {warningAlert}
                                    <Container fluid className="d-flex pb-3 pt-2 px-0 m-0" style={{ height: `calc(100vh - ${elementsHeight}px)` }}>
                                        {table}
                                    </Container>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Fragment>
        </IfGranted>
    )
}

export default AdHocQuery;
