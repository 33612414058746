import React, { Fragment, useState, useEffect, useContext } from 'react'
import AsyncSelect from 'react-select/async';
import { useTranslation } from 'react-i18next';
import { AlertContext } from '../../context/AlertContext';
import { AuthContext } from '../../context/AuthContext';
import Spinner from '../UI/Spinner';
import Button from 'react-bootstrap/Button';
import { CacheInstance } from '../../utils/AbstractCacheInstance';
import { HasIdName } from '../../apiClient/TrackingConfigurationClient';
import { Col, Container, Row } from 'react-bootstrap';

interface SelectSearchProps<T> {
    value: string
    onChange: (value: string) => void,
    placeholder: string
    showClearButton?: boolean
    idAsLabel?: boolean
    cache: CacheInstance<any>
    classNamePrefix: string
    userIdOfSelectedProject?: string
    isDisabled?: boolean
    filterByAttribute?: (inputValue: String, objects: Array<T>) => Array<T>
    getLabel?: (obj: T) => string
}

interface SelectedId {
    id: string
}

function SelectSearch<T extends HasIdName>(props: SelectSearchProps<T>) {
    const [selectedObject, setSelectedObject] = useState<T | null>(null);
    const [loading, setLoading] = useState(true);
    const [objects, setObjects] = useState<Array<T>>([]);

    const authContext = useContext(AuthContext);
    const alertContext = useContext(AlertContext);

    const { t } = useTranslation();

    const handleChange = (value: any) => {
        setSelectedObject(value);
        props.onChange(value.id);
    }

    const filterById = (name: string): Array<T> => {
        const inputId = name.toLocaleLowerCase();
        return objects.filter((object: T) => object.id.toLocaleLowerCase().startsWith(inputId));

    }
    const filterByName = (name: string): Array<T> => {
        return objects.filter(
            (obj: T) => obj.name.toLocaleLowerCase().startsWith(name.toLocaleLowerCase())
        );
    }

    const getObjectToLabelFunction = (obj: T): string => {
        return props.idAsLabel ? obj.id : obj.name;
    }

    useEffect(() => {
        if (authContext.isAuthenticated) {
            props.cache.reload(setLoading, true)
                .then((response: Array<T>) => {
                    setObjects(response);
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(t("error in processing response from server", { error: reason }));
                })
        }
    }, [loading, authContext.isAuthenticated]);

    useEffect(() => {
        if (props.value == null) {
            setSelectedObject(null)
        }
        else {
            setSelectedObject(props.cache.get().getById(props.value));
        }
    }, [props.value])

    useEffect(() => {
        if (props.value === '') {
            setSelectedObject(null);
        }
    }, [props.value]);

    const loadObjectNames = async (inputValue: string) => {
        if (props.filterByAttribute !== undefined) {
            return props.filterByAttribute(inputValue, props.cache.get().getCachedElements());
        }
        return filterByName(inputValue);
    };

    const loadObjectIds = async (inputValue: string) => {
        return filterById(inputValue);
    };

    const getOptionLabelFunc = props.getLabel ? props.getLabel : getObjectToLabelFunction;

    let defaultOptions: Array<T> = props.cache.get().getCachedElements();
    if (props.userIdOfSelectedProject !== undefined) {
        defaultOptions = new Array<T>();
        defaultOptions.push(props.cache.get().getById(props.userIdOfSelectedProject));
    }

    const asyncSelect = <AsyncSelect
        classNamePrefix={`${props.classNamePrefix}AsyncSelect`}
        placeholder={props.placeholder}
        cacheOptions
        defaultOptions={defaultOptions}
        value={selectedObject}
        getOptionLabel={getOptionLabelFunc}
        getOptionValue={e => e.id}
        loadOptions={props.idAsLabel ? loadObjectIds : loadObjectNames}
        onChange={handleChange}
        isDisabled={props.isDisabled}
    />

    let content = null;

    if (loading) {
        content = <Spinner />
    } else if (props.showClearButton) {
        content = <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Row noGutters={true}>
                <Col>
                    {asyncSelect}
                </Col>
                <Col md="auto">
                    <Button
                        variant="primary"
                        type="submit"
                        onClick={() => props.onChange('')}
                        data-cy={`clear-button-${props.classNamePrefix}`}
                        style={{ marginLeft: 10 }}
                    >
                        {t("clear")}
                    </Button>
                </Col>
            </Row>
        </Container>
    }
    else {
        content = asyncSelect;
    }

    return (
        <Fragment>
            { content}
        </Fragment>
    );
}

export default SelectSearch;