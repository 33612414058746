import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Role from './interfaces';
import AddRoleForm from './AddRoleForm';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

interface AddRoleButtonProps {
    onRoleAdded: (role: Role) => void
    disabled: boolean
}

const AddRoleButton = (props: AddRoleButtonProps) => {
    const [showAddRoleModal, setShowAddRoleModal] = useState(false);
    const handleCloseAddRoleModal = () => setShowAddRoleModal(false);
    const handleShowAddRoleModal = () => setShowAddRoleModal(true);

    const { t } = useTranslation();

    if (showAddRoleModal) {
        return (
            <Modal
                show={showAddRoleModal}
                onHide={handleCloseAddRoleModal}
                keyboard={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{ t('add role') }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddRoleForm
                        onRoleAdded={props.onRoleAdded}
                        handleCloseAddRoleModal={handleCloseAddRoleModal}
                    />
                </Modal.Body>
            </Modal>
        )
    } else {
        return (
            <Button className="float-right" variant="primary" onClick={handleShowAddRoleModal} disabled={props.disabled} data-cy="add-role-button">
                { t('add role') }
            </Button>
        )
    }
}

export default AddRoleButton;
