import { DateRange } from '../components/UI/DatePicker';
import config from '../config/config';
import { BehaviorDataRecord, FunnelDataRecord, TableRecord } from '../containers/Analysis/interfaces';
import { getToken } from '../utils/JwtToken';

interface StartLogQueryRequestDTO {
    projectId: string,
    eventTypes: string[],
    appVersion: string,
    userId: string
}

export interface StartQueryResponseDTO {
    athena_query_id: string
}

export interface QueryResponseDTO {
    data: Object[],
    status_code: number,
}

class UserBehaviorDataApiClient {

    baseURL: string

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    getDateRangeParams(dateRange: DateRange): URLSearchParams {
        return new URLSearchParams({
            "from_date": dateRange?.startDate?.format('YYYY-MM-DD') || "",
            "to_date": dateRange?.endDate?.format('YYYY-MM-DD') || "",
        })
    }

    async getData(endpointName: string, dateRange: DateRange, params?: URLSearchParams): Promise<Array<any>> {
        let apiUrl = `${this.baseURL}/${endpointName}?${this.getDateRangeParams(dateRange)}`;
        if (params !== undefined)
            apiUrl += `&${params}`
        const response = await fetch(
            apiUrl,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        );
        if (!response.ok || response.status !== 200)
            throw Error('wrong status code on api response');
        return await response.json();
    }

    async getFunnelData(dateRange: DateRange): Promise<Array<FunnelDataRecord>> {
        return this.getData('funnel', dateRange);
    }

    async getBehaviorData(dateRange: DateRange, maxStep: number = 10): Promise<Array<BehaviorDataRecord>> {
        const params = new URLSearchParams({ "max_step": `${maxStep}` })
        return this.getData('user_path', dateRange, params);
    }

    async postQuery(query: string): Promise<Array<Object>> {
        const response = await fetch(
            `${this.baseURL}/query`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify({ query: query }),
            }
        );
        if (!response.ok || response.status !== 200)
            throw Error('wrong status code on api response');
        return await response.json();
    }

    async startAthenaQuery(query: string): Promise<StartQueryResponseDTO> {
        const url = `${this.baseURL}/start_athena_query`;
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify({ query: query })
            }
        );
        if (!response.ok || response.status !== 202)
            throw Error('wrong status code on api response');
        return await response.json();
    }

    handleError(err: any) {
        console.warn(err);
        return new Response(JSON.stringify({
            code: 400,
            message: 'Stupid network Error'
        }));
    };

    async getAthenaQueryResult(queryId: string): Promise<QueryResponseDTO> {
        const url = `${this.baseURL}/get_athena_query_result/${queryId}`;
        const response = await (fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        ).catch(this.handleError));
        if (!response.ok || (response.status !== 200 && response.status !== 204))
            throw Error('wrong status code on api response');
        const data = (response.status === 200) ? await response.json() : [];
        return {
            data: data,
            status_code: response.status,
        }
    }

    async stopAthenaQuery(queryId: string): Promise<void> {
        const url = `${this.baseURL}/stop_athena_query/${queryId}`;
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        );
        if (!response.ok || response.status !== 200)
            throw Error('wrong status code on api response');
        return await response.json();
    }

    async listTables(): Promise<Array<TableRecord>> {
        const response = await fetch(
            `${this.baseURL}/list_query_tables`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
            }
        );
        if (!response.ok || response.status !== 200)
            throw Error('wrong status code on api response');
        return await response.json();
    }

    async get_raw_events(projectId: string, events: string[], appVersion: string, userId: string): Promise<[{}]> {
        const searchParams = this.buildSearchParams(events, appVersion, userId);
        const url = `${this.baseURL}/get_new_events/${projectId}?${searchParams}`;
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
            }
        );
        if (!response.ok || response.status !== 200)
            throw Error('wrong status code on api response');
        return await response.json();
    }

    private buildSearchParams(events: string[], appVersion: string, userId: string) {
        const searchParams = new URLSearchParams();
        events.forEach(event => {
            if (this.validateValue(event)) {
                searchParams.append('event_type', event)
            }
        })
        if (this.validateValue(appVersion)) {
            searchParams.append('app_version', appVersion);
        }
        if (this.validateValue(userId)) {
            searchParams.append('user_id', userId);
        }
        return searchParams;
    }

    private validateValue(value: string) {
        return value !== null && value.trim() !== '';
    }

    async startLogQuery(startLogQueryRequest: StartLogQueryRequestDTO): Promise<StartQueryResponseDTO> {
        const url = `${this.baseURL}/start_log_query`;
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(startLogQueryRequest)
            }
        );
        if (!response.ok || response.status !== 202)
            throw Error('wrong status code on api response');
        return await response.json();
    }

    async getLogQueryResult(queryId: string): Promise<QueryResponseDTO> {
        const url = `${this.baseURL}/get_log_query_result/${queryId}`;
        const response = await fetch(url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        );
        if (!response.ok || (response.status !== 200 && response.status !== 204))
            throw Error('wrong status code on api response');
        const data = (response.status === 200) ? await response.json() : [];
        return {
            data: data,
            status_code: response.status,
        }
    }


    async stopLogQuery(queryId: string): Promise<void> {
        const url = `${this.baseURL}/stop_log_query/${queryId}`;
        const response = await fetch(url,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        );
        if (!response.ok || response.status !== 200)
            throw Error('wrong status code on api response');
        return await response.json();
    }
};

const instance = new UserBehaviorDataApiClient(config.api.userBehaviorData.BASE_URL);

export default instance;
