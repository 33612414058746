import React, { Fragment } from 'react';
import Toolbar from './Toolbar';
import Footer from './Footer';
import AlertMessage from '../../components/Alert/AlertMessage';

const Layout = (props:any) => {
    return (
        <Fragment>
            <Toolbar />
            <div className="mx-5 my-4">
                <AlertMessage />
                {props.children}
            </div>
            <Footer />
        </Fragment>
    )
}

export default Layout;
