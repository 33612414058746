import React from 'react';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

interface ProjectTypeSelectProps {
    value: string,
    onChange: (value: string) => void
}

const ProjectTypeSelect = (props: ProjectTypeSelectProps) => {

    const { t } = useTranslation();

    return (
        <Form.Control
            as="select"
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}
            style={{ width: "200px" }}
        >
            <option value="" disabled selected hidden>{t("select project type")}</option>
            <option value="Android_smartphone">Android_smartphone</option>
            <option value="AndroidBigscreen">AndroidBigscreen</option>
            <option value="iOS">iOS</option>
            <option value="Web">Web</option>
            <option value="Linux">Linux</option>
            <option value="Mini Programs">Mini programs</option>
        </Form.Control>
    )
}

export default ProjectTypeSelect;
