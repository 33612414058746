import React, { useState, useCallback, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Role from './interfaces';
import accessControlManagementApiClient from '../../../apiClient/AccessControlManagementClient';
import SmallErrorMessage from '../../../components/Forms/SmallErrorMessage';
import { AlertContext } from '../../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import { rightsList } from '../../../auth/RightsUtils'
import { SelectComponentStyle } from '../../../components/Styles/SelectComponentStyle';

// TODO: consider https://react-bootstrap.github.io/components/forms/#forms-validation-libraries

interface AddRoleFormProps {
    onRoleAdded: (role: Role) => void
    handleCloseAddRoleModal: () => void
}

const AddRoleForm = (props: AddRoleFormProps) => {
    const alertContext = useContext(AlertContext);

    const [roleType, setRoleType] = useState('');
    const [description, setDescription] = useState('');
    const [rights, setRights] = useState([]);

    const [roleTypeIsInvalid, setRoleTypeIsInvalid] = useState(false);
    const [descriptionIsInvalid, setDescriptionIsInvalid] = useState(false);
    const [rightsAreInvalid, setRightsAreInvalid] = useState(false);

    const [invalidRoleTypeMessage, setInvalidRoleTypeMessage] = useState('')

    const { t } = useTranslation();

    const handleAddRoleButtonClick = useCallback((event: any) => {
        event.preventDefault();

        let formIsValid = true;

        if (roleType.trim() === '') {
            setRoleTypeIsInvalid(true);
            setInvalidRoleTypeMessage(t("role type can't be empty"))
            formIsValid = false;
        }
        else {
            setRoleTypeIsInvalid(false);
        }

        if (description.trim() === '') {
            setDescriptionIsInvalid(true);
            formIsValid = false;
        }
        else {
            setDescriptionIsInvalid(false);
        }

        if (rights === undefined || rights.length === 0) {
            setRightsAreInvalid(true);
            formIsValid = false;
        }
        else {
            setRightsAreInvalid(false);
        }

        if (formIsValid) {
            accessControlManagementApiClient.addRole({
                type: roleType,
                description: description,
                rights: rights
            })
                .then((response: Role) => {
                    props.onRoleAdded(response);
                    alertContext.showSuccessAlert(t('role was successfully added', { type: roleType }));
                    props.handleCloseAddRoleModal();

                    // clear form
                    setRoleType('');
                    setDescription('');
                })
                .catch((reason) => {
                    const stringifyMessage = JSON.stringify(reason.message)
                    if (stringifyMessage.includes("already exists")) {
                        setRoleTypeIsInvalid(true);
                        setInvalidRoleTypeMessage(t("role type already exists", { type: roleType }))
                    }
                    else {
                        props.handleCloseAddRoleModal();
                        alertContext.showErrorAlert(stringifyMessage);
                    }
                });
        }
    }, [props, roleType, description, rights]);

    const setRightsOnChangeHandler = useCallback(
        (right) => {
            setRights(right?.map((right: any, i: any) => right.value));
        }, []);

    return (
        <Form>
            <Form.Group controlId="type" className="required">
                <Form.Label>{t("role type")}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t("enter role type")}
                    value={roleType}
                    onChange={(event) => setRoleType(event.target.value)}
                    autoComplete="off"
                />
                <SmallErrorMessage show={roleTypeIsInvalid} message={invalidRoleTypeMessage} />
            </Form.Group>

            <Form.Group controlId="description" className="required">
                <Form.Label>{t("description")}</Form.Label>
                <Form.Control
                    type="text"
                    placeholder={t("enter role description")}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    autoComplete="off"
                />
                <SmallErrorMessage show={descriptionIsInvalid} message={t("project description can't be empty")} />
            </Form.Group>

            <Form.Group controlId="rights" className="required">
                <Form.Label>{t("rights")}</Form.Label>
                <Select
                    id="rights"
                    options={rightsList}
                    defaultValue={rights}
                    isMulti
                    styles={SelectComponentStyle}
                    onChange={setRightsOnChangeHandler}
                    noOptionsMessage={() => t("no options")}
                />
                <SmallErrorMessage show={rightsAreInvalid} message={t("rights list can't be empty")} />
            </Form.Group>

            <Modal.Footer>
                <Button className="btn btn-secondary mr-auto" onClick={props.handleCloseAddRoleModal}>{t('cancel')}</Button>
                <Button variant="primary" type="submit" onClick={handleAddRoleButtonClick} >{t("submit")}</Button>
            </Modal.Footer>

        </Form>
    );
}

export default AddRoleForm;
