import * as React from 'react';
import { useState, useCallback } from 'react';

export interface Alert {
    variant:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'dark'
    | 'light';
    message: string
}

export interface AlertContextInterface {
    isAlertVisible(): boolean
    getAlert(): Alert
    showAlert: (alert: Alert) => void
    showSuccessAlert: (message: string) => void
    showErrorAlert: (message: string) => void
    clearAlert: () => void
}

export const AlertContext = React.createContext<AlertContextInterface>({
    isAlertVisible: () => false,
    getAlert: () => ({variant: 'primary', message: ''}),
    showAlert: (alert: Alert) => {},
    showSuccessAlert: (message: string) => {},
    showErrorAlert: (message: string) => {},
    clearAlert: () => {}
});

const AlertContextProvider: React.FC = (props) => {

    const [alertVisible, setAlertVisible] = useState(false);
    const [alert, setAlert] = useState<Alert>({variant: 'primary', message: ''});
    const [timeoutHandler, setTimeoutHandler] = useState<NodeJS.Timeout | null>(null);

    const showAlert = useCallback((alert: Alert) => {
        setAlert(alert);
        setAlertVisible(true);
        // prevent hiding alert to fast when having multiple alerts
        if(timeoutHandler) {
            clearTimeout(timeoutHandler);
        }
        setTimeoutHandler(setTimeout(() => setAlertVisible(false), parseInt(process.env.REACT_APP_ALERT_VISIBILITY_MSEC || "")));
    }, [timeoutHandler]);

    return (
        <AlertContext.Provider
            value={{
                isAlertVisible: () => alertVisible,
                getAlert: () => alert,
                showAlert: showAlert,
                showSuccessAlert: (message: string) => {
                    showAlert({
                        variant: "success",
                        message
                    });
                },
                showErrorAlert: (message: string) => {
                    showAlert({
                        variant: "danger",
                        message
                    });
                },
                clearAlert: () => setAlertVisible(false)
            }}
        >
            {props.children}
        </AlertContext.Provider>
    );
};

export default AlertContextProvider;
