import React, { useCallback } from 'react';
import Select from 'react-select';
import { SelectComponentStyle } from '../../../components/Styles/SelectComponentStyle';
import { AutoTrackingOptions } from './AutoTrackingMode';
import { useTranslation } from 'react-i18next';

interface AutoTrackingModeSelectProps {
    id: string
    value: number
    onChange: (value: number) => void
    disabled: boolean
    placeholder?: string
    autoTrackingOptions: AutoTrackingOptions
}

const AutoTrackingModeSelect = (props: AutoTrackingModeSelectProps) => {

    const { t } = useTranslation();

    const values = props.autoTrackingOptions.convertNumberToAutoTrackingOptions(props.value);

    const onChangeHandler = useCallback(
        (options) => {
            const newValue = props.autoTrackingOptions.calculateAutoTrackingMode(options)
            props.onChange(newValue);
        }, [props]);

    return (
        <Select
            id={props.id}
            defaultValue={values}
            options={props.autoTrackingOptions.getTrackingOptions()}
            isMulti
            onChange={onChangeHandler}
            styles={SelectComponentStyle}
            isDisabled={props.disabled}
            placeholder={props.placeholder}
            noOptionsMessage={() => t("no options")}
        />
    )
}

export default AutoTrackingModeSelect;
