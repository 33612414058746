import trackingConfigurationApiClient from '../../../apiClient/TrackingConfigurationClient';
import { AbstractCacheInstance } from '../../../utils/AbstractCacheInstance';
import ProjectsSdkConfig from './interfaces';

class SdkConfigCache extends AbstractCacheInstance<ProjectsSdkConfig> {

    async reload(
        setLoadingComponent: (value: boolean) => void,
        forceReload: boolean = false
    ): Promise<Array<ProjectsSdkConfig>> {
        const response = this.get().reload(
            trackingConfigurationApiClient,
            trackingConfigurationApiClient.getSdkConfigInformation,
            setLoadingComponent,
            forceReload);
        return await response;
    }
}

export default new SdkConfigCache();
