import React from 'react';
import Badge from 'react-bootstrap/Badge';

interface TagProps {
    tag: string
}

const Tag = (props: TagProps) => {
    return (<Badge variant="primary" className="mr-1">{props.tag}</Badge>);
}
 
export default Tag;