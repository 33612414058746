import React, { useState, useCallback, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import User from './interfaces';
import accessControlManagementApiClient from '../../../apiClient/AccessControlManagementClient';
import SmallErrorMessage from '../../../components/Forms/SmallErrorMessage';
import { AlertContext } from '../../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal';
import SelectSearch from '../../../components/Forms/SelectSearch';
import Role from '../Roles/interfaces';
import rolesCache from '../Roles/rolesCache';

interface AddUserFormProps {
    onUserAdded: (user: User) => void
    handleCloseAddUserModal: () => void
    showAddUserModal: boolean
}

function AddUserForm(props: AddUserFormProps) {
    const alertContext = useContext(AlertContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [roleId, setRoleId] = useState('');

    const [nameIsInvalid, setNameIsInvalid] = useState(false);
    const [emailIsInvalid, setEmailIsInvalid] = useState(false);
    const [descriptionIsInvalid, setDescriptionIsInvalid] = useState(false);
    const [roleIdIsInvalid, setRoleIdIsInvalid] = useState(false);

    const [invalidEmailMessage, setInvalidEmailMessage] = useState('')
    const [invalidNameMessage, setInvalidNameMessage] = useState('')

    const { t } = useTranslation();

    const setInvalidMessage = useCallback((restMessage: string) => {
        if (restMessage.includes("mail")) {
            setEmailIsInvalid(true);
            setInvalidEmailMessage(t("email already exists", { email: email }));
        }
        else {
            setNameIsInvalid(true);
            setInvalidNameMessage(t("name already exists", { name: name }));
        }
    }, [email, name, t])

    const handleAddUserButtonClick = useCallback((event: any) => {
        event.preventDefault();

        let formIsValid = true;

        if (name.trim() === '') {
            setNameIsInvalid(true);
            setInvalidNameMessage(t("user name can't be empty"))
            formIsValid = false;
        }
        else {
            setNameIsInvalid(false);
        }

        if (email.trim() === '' || !email.endsWith("@tcl.com")) {
            setEmailIsInvalid(true);
            setInvalidEmailMessage(t("email can't be empty and have to have tcl domain"))
            formIsValid = false;
        }
        else {
            setEmailIsInvalid(false);
        }

        if (description.trim() === '') {
            setDescriptionIsInvalid(true);
            formIsValid = false;
        }
        else {
            setDescriptionIsInvalid(false);
        }

        if (roleId.trim() === '') {
            setRoleIdIsInvalid(true);
            formIsValid = false;
        }
        else {
            setRoleIdIsInvalid(false);
        }

        if (formIsValid) {
            accessControlManagementApiClient.addUser({
                name: name,
                email: email,
                description: description,
                roleId: roleId
            })
                .then((response: User) => {
                    props.onUserAdded(response);
                    alertContext.showSuccessAlert(t('user was successfully added', { name: name }));
                    props.handleCloseAddUserModal();

                    // clear form
                    setName('');
                    setEmail('');
                    setDescription('');
                    setRoleId('');
                })
                .catch((reason) => {
                    const stringifyMessage = JSON.stringify(reason.message)
                    if (stringifyMessage.includes("already exists")) {
                        setInvalidMessage(stringifyMessage);
                    }
                    else {
                        props.handleCloseAddUserModal();
                        alertContext.showErrorAlert(stringifyMessage);
                    }
                })
        }
    }, [name, email, description, roleId, setInvalidMessage]);


    return (
        <Modal
            show={props.showAddUserModal}
            onHide={props.handleCloseAddUserModal}
            keyboard={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t('add user')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="name" className="required">
                        <Form.Label>{t("user name")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("enter user name")}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            autoComplete="off"
                        />
                        <SmallErrorMessage show={nameIsInvalid} message={invalidNameMessage} />
                    </Form.Group>
                    <Form.Group controlId="email" className="required">
                        <Form.Label>{t("mail")}</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder={t("enter email")}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                        />
                        <SmallErrorMessage show={emailIsInvalid} message={invalidEmailMessage} />
                    </Form.Group>
                    <Form.Group controlId="description" className="required">
                        <Form.Label>{t("description")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("enter user description")}
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                            autoComplete="off"
                        />
                        <SmallErrorMessage show={descriptionIsInvalid} message={t("description can't be empty")} />
                    </Form.Group>

                    <Form.Group controlId="roleType" className="required">
                        <Form.Label>{t('role type')}</Form.Label>
                        <SelectSearch<Role>
                            value={roleId}
                            onChange={setRoleId}
                            placeholder={t("enter role type")}
                            cache={rolesCache}
                            classNamePrefix="role"
                            getLabel={(obj: Role): string => obj.type}
                            filterByAttribute={(inputValue: String, objects: Array<Role>) => {
                                return objects.filter((obj: Role) => obj.type.toLocaleLowerCase().startsWith(inputValue.toLocaleLowerCase()))
                            }}
                        />
                        <SmallErrorMessage show={roleIdIsInvalid} message={t("role type can't be empty")} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-secondary mr-auto" onClick={props.handleCloseAddUserModal}>{t('cancel')}</Button>
                <Button variant="primary" type="submit" onClick={handleAddUserButtonClick} >{t("submit")}</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddUserForm;