export enum Rights {
    SdkRead = "trackingConfiguration.sdk.read",
    SdkWrite = "trackingConfiguration.sdk.write",
    ProjectRead = "trackingConfiguration.projects.read",
    ProjectWrite = "trackingConfiguration.projects.write",
    UserRead = "accessManagement.users.read",
    UserWrite = "accessManagement.users.write",
    RoleRead = "accessManagement.role.read",
    RoleWrite = "accessManagement.role.write",
    AnalysisFunnelRead = "analysis.funnel.read",
    AnalysisUserBehaviorRead = "analysis.userBehavior.read",
    AnalysisQueryRead = "analysis.query.read",
    LogQueryRead = "analysis.logQuery.read",
    MainDashboardRead = "bi.mainDashboard.read",
    EventDefinitionRead = "trackingConfiguration.eventDefinition.read"
}