import React, { Fragment, useState, useCallback, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import ProjectsSdkConfig from './interfaces';
import trackingConfigurationApiClient from '../../../apiClient/TrackingConfigurationClient';
import { AlertContext } from '../../../context/AlertContext';
import { useTranslation } from 'react-i18next';
import AutoTrackingModeSelect from './AutoTrackingModeSelect';
import projectsCache from './../Projects/projectsCache';
import CancelSaveButtons from '../../../components/Forms/CancelSaveButtons';
import { checkIsWebTypeProject, defineAutoTrackMode, getAutoTrackingOptions } from '../../utils';
import '../../../styles/WrapAnywhere.css';

interface EditProjectSdkFormProps {
    sdkConfig: ProjectsSdkConfig,
    onCancelClick: (event: any) => void,
    onProjectEdited: (project: ProjectsSdkConfig) => void,
}

const notApplicableFormElement = <td>
    <Form.Control
        readOnly
        defaultValue={"N/A"} />
</td>;
const EditProjectSdkForm = (props: EditProjectSdkFormProps) => {
    const sdkConfig = props.sdkConfig;

    const alertContext = useContext(AlertContext);

    const [autoTrackMode, setAutoTrackMode] = useState(sdkConfig.autoTrackMode);
    const [enableJavaScriptBridge, setEnableJavaScriptBridge] = useState(sdkConfig.enableJavaScriptBridge);
    const [flushInterval, setFlushInterval] = useState(sdkConfig.flushInterval);
    const [flushBulkSize, setFlushBulkSize] = useState(sdkConfig.flushBulkSize);
    const [singleFlushSize, setSingleFlushSize] = useState(sdkConfig.singleFlushSize);
    const [maxCacheSize, setMaxCacheSize] = useState(sdkConfig.maxCacheSize);
    const [disableSDK, setDisableSDK] = useState(sdkConfig.disableSDK);

    let configNotChanged = false;

    const { t } = useTranslation();

    const isWebProject = checkIsWebTypeProject(sdkConfig.projectId, t);

    if (sdkConfig.autoTrackMode === autoTrackMode
        && sdkConfig.enableJavaScriptBridge === enableJavaScriptBridge
        && sdkConfig.flushInterval === flushInterval
        && sdkConfig.flushBulkSize === flushBulkSize
        && sdkConfig.singleFlushSize === singleFlushSize
        && sdkConfig.maxCacheSize === maxCacheSize
        && sdkConfig.disableSDK === disableSDK) {
        configNotChanged = true
    }

    const handleSaveButtonClick = useCallback((event: any) => {

        event.preventDefault();

        const updateProjectSdkRequest = {
            projectId: sdkConfig.projectId,
            autoTrackMode: disableSDK ? -1 : autoTrackMode === -1 ? 0 : defineAutoTrackMode(autoTrackMode, isWebProject),
            enableJavaScriptBridge: enableJavaScriptBridge,
            flushInterval: flushInterval,
            flushBulkSize: flushBulkSize,
            singleFlushSize: singleFlushSize,
            maxCacheSize: maxCacheSize,
            disableSDK: disableSDK
        };

        const displayUpdateMessage = (response: ProjectsSdkConfig) => {
            if ("detail" in response && "exception" in response["detail"]) {
                if ("exception_message" in response["detail"]) {
                    // TODO: We don't support translations for messages returned from backend.
                    alertContext.showErrorAlert(response["detail"]["exception_message"]);
                } else {
                    alertContext.showErrorAlert(t("problem with updating project"));
                }
            } else {
                alertContext.showSuccessAlert(t("updated SDK config for project", { name: sdkConfig.projectId }));
            }
        }

        trackingConfigurationApiClient.updateSdkConfig(sdkConfig.id, updateProjectSdkRequest)
            .then((response: ProjectsSdkConfig) => {
                displayUpdateMessage(response);
                props.onProjectEdited(response);
            })
            .catch((reason) => {
                // NOTE: This catch is triggered only if an exception is thrown when processing 'then' clause.
                alertContext.showErrorAlert(t("problem with updating project"));
            });
    }, [autoTrackMode, enableJavaScriptBridge, flushInterval, flushBulkSize, singleFlushSize, maxCacheSize, disableSDK]);

    return (
        <Fragment>
            <td className="wrap-anywhere">
                {sdkConfig.projectId}
            </td>
            <td>
                <Form.Control readOnly value={projectsCache.getProjectNameByProjectId(sdkConfig.projectId, t)} />
            </td>
            <td>
                <Form.Control readOnly value={projectsCache.getProjectTypeByProjectId(sdkConfig.projectId, t)} />
            </td>
            <td>
                <Form.Control readOnly value={projectsCache.getUserIdByProjectId(sdkConfig.projectId, t)} />
            </td>
            <td style={{ minWidth: "230px" }}>
                <AutoTrackingModeSelect
                    id="autoTrackMode"
                    value={autoTrackMode}
                    onChange={setAutoTrackMode}
                    disabled={disableSDK}
                    data-cy="auto-tracking-mode-select"
                    autoTrackingOptions={getAutoTrackingOptions(sdkConfig.projectId, t)}
                />
            </td>
            {
                // Here should be defined EnableJavaScript bridge for web type project
                notApplicableFormElement
            }
            {isWebProject ? notApplicableFormElement :
                <td>
                    <Form.Control
                        type="number"
                        placeholder={t("enter flush interval")}
                        value={flushInterval}
                        onChange={(event) => setFlushInterval(parseInt(event.target.value))}
                        disabled={disableSDK}
                        data-cy="enter-flush-interval-form-control"
                        style={{ width: "100px" }}
                    />
                </td>
            }
            {isWebProject ? notApplicableFormElement :
                <td>
                    <Form.Control
                        type="number"
                        placeholder={t("enter flush bulk size")}
                        value={flushBulkSize}
                        onChange={(event) => setFlushBulkSize(parseInt(event.target.value))}
                        disabled={disableSDK}
                        data-cy="enter-flush-bulk-size-form-control"
                        style={{ width: "100px" }}
                    />
                </td>
            }
            {isWebProject ? notApplicableFormElement :
                <td>
                    <Form.Control
                        type="number"
                        placeholder={t("enter single flush size")}
                        value={singleFlushSize}
                        onChange={(event) => setSingleFlushSize(parseInt(event.target.value))}
                        disabled={disableSDK}
                        data-cy="enter-single-flush-size-form-control"
                        style={{ width: "100px" }}
                    />
                </td>
            }
            {isWebProject ? notApplicableFormElement :
                <td>
                    <Form.Control
                        type="number"
                        placeholder={t("enter max cache size")}
                        value={maxCacheSize}
                        onChange={(event) => setMaxCacheSize(parseInt(event.target.value))}
                        disabled={disableSDK}
                        data-cy="enter-max-cache-size-form-control"
                        style={{ width: "120px" }}
                    />
                </td>
            }
            <td>
                <Form.Control
                    readOnly
                    defaultValue={sdkConfig.updateTime}
                />
            </td>
            <td>
                <Form.Control
                    as="select"
                    type="text"
                    value={String(disableSDK)}
                    onChange={(event) => setDisableSDK(JSON.parse(event.target.value))}
                >
                    <option value="false">enable</option>
                    <option value="true">disable</option>
                </Form.Control>
            </td>
            <td>
                <CancelSaveButtons
                    onCancelClick={props.onCancelClick}
                    onSaveClick={handleSaveButtonClick}
                    configNotChanged={configNotChanged}
                />
            </td>
        </Fragment>
    );
}

export default EditProjectSdkForm;
