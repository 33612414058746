import { Rights } from "./Rights";

export function getRights(token: string | null): string[] {
    if (token === null) {
        return []
    }
    return JSON.parse(atob(token.split(".")[1])).rights
}

export function hasRights(userRigshts: string[], ...requiredRights: string[]): boolean {
    return requiredRights.every(v => userRigshts.includes(v));
}

export const rightsList = Array.from(enumToMap(Rights).entries()).map(m => ({ value: m[1], label: m[0] }));

function enumToMap(enumeration: any): Map<string, string | number> {
    const map = new Map<string, string | number>();
    for (let key in enumeration) {
        if (!isNaN(Number(key))) continue;
        const val = enumeration[key] as string | number;
        if (val !== undefined && val !== null)
            map.set(key, val);
    }
    return map;
}