import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Project from './interfaces';
import AddProjectForm from './AddProjectForm';
import { useTranslation } from 'react-i18next';

interface AddProjectButtonProps {
    onProjectAdded: (project: Project) => void
    disabled: boolean
}

const AddProjectButton = (props: AddProjectButtonProps) => {

    const [showAddProjectModal, setShowAddProjectModal] = useState(false);
    const handleCloseAddProjectModal = () => setShowAddProjectModal(false);
    const handleShowAddProjectModal = () => setShowAddProjectModal(true);

    const { t } = useTranslation();

    if (showAddProjectModal) {
        return (
            <AddProjectForm
                onProjectAdded={props.onProjectAdded}
                handleCloseAddProjectModal={handleCloseAddProjectModal}
                showAddProjectModal={showAddProjectModal}
            />
        )
    } else {
        return (
            <Button className="float-right" variant="primary" onClick={handleShowAddProjectModal} disabled={props.disabled} data-cy="add-project-button">
                {t("add project")}
            </Button>
        )
    }
}

export default AddProjectButton;
