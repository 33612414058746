export const SelectComponentStyle = {
    multiValueLabel: (styles: any, _: any) => ({
        ...styles,
        color: "#fff",
        backgroundColor: "#007bff",
        display: "inline-block",
        padding: ".25em .4em",
        fontSize: "75%",
        fontWeight: 700,
        lineHeight: 1,
        textAlign: "center",
        whiteSpace: "nowrap",
        overflow: "visible",
        verticalAlign: "baseline",
        borderRadius: ".25rem 0 0 .25rem",
        transition: "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out"

    }),
    multiValueRemove: (styles: any, _: any) => ({
        ...styles,
        color: "white",
        backgroundColor: "#007bff",
        borderRadius: "0 .25rem .25rem 0",
        ':hover': {
            backgroundColor: "#007bff",
            color: "black",
        },
    }),
};