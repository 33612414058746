import React, { Fragment, useCallback, useState, useContext } from 'react';
import EditIcon from '../../../components/Icons/EditIcon';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import Project from './interfaces';
import trackingConfigurationApiClient from '../../../apiClient/TrackingConfigurationClient';
import DeleteConfirmationModal from '../../../components/Modal/DeleteConfirmationModal';
import { AlertContext } from '../../../context/AlertContext';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import DateTimeInTimezone from '../../../utils/DatetimeTz'
import { SelectOptions } from './fixtures';
import { IfGranted } from 'react-authorization'
import { Rights } from '../../../auth/Rights';
import '../../../styles/WrapAnywhere.css';

interface ProjectRowProps {
    project: Project,
    onEditClick: (event: any) => void,
    onProjectDeleted: (projectId: string) => void,
}

const ProjectRow = (props: ProjectRowProps) => {

    const project = props.project;

    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const { t } = useTranslation();

    const handleDeleteButtonClicked = useCallback((event: any) => {
        event.preventDefault();

        trackingConfigurationApiClient.deleteProject(project.id)
            .then((response: any) => {
                props.onProjectDeleted(project.id);
                alertContext.showSuccessAlert(t('removed project', { name: project.name }));
            })
            .catch((reason) => {
                alertContext.showErrorAlert(t("problem with deleting project from server"));
            });
    }, [props, project]);

    return (
        <Fragment>
            <td>{project.name}</td>
            <td>{project.projectType}</td>
            <td className="wrap-anywhere">{project.userId}</td>
            <td className="wrap-anywhere">{project.id}</td>
            <td>{project.description}</td>
            <td>{project.forwardToSensorsData
                 ? SelectOptions.ForwardToSensorsData.true
                 : SelectOptions.ForwardToSensorsData.false}</td>
            <td><DateTimeInTimezone utcDateTime={project.updateTime} /></td>
            <IfGranted expected={Rights.SdkWrite} actual={authContext.userRights}>
            <td>
                <EditIcon onClick={props.onEditClick} />
                <DeleteIcon onClick={(_: any) => setShowDeleteConfirmationModal(true)} />
                <DeleteConfirmationModal
                    show={showDeleteConfirmationModal}
                    heading={t("delete project")}
                    body={t("do you want to delete project and related sdk configurations?")}
                    onCancelButtonClicked={(_: any) => setShowDeleteConfirmationModal(false)}
                    onDeleteButtonClicked={handleDeleteButtonClicked}
                />
            </td>
            </IfGranted>
        </Fragment>
    );
}

export default ProjectRow;
