import React, { Fragment, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useTranslation } from 'react-i18next';

interface PaginationProps {
    pageCount: number,
    itemsCount: number,
    onPageChange: (selectedPage: number) => void
}

const Pagination = (props: PaginationProps) => {
    const [enteredPageNumber, setEnteredPageNumber] = useState("");
    const [currentPage, setCurrentPage] = useState(0); // 0 - first page
    const { t } = useTranslation();

    const validateEnteredPageNumber = (enteredNumber: string, totalPageNumber: number) => {
        let num = Number(enteredNumber);
        if (num < 1 || num > totalPageNumber) {
            setEnteredPageNumber("");
        } else {
            setEnteredPageNumber(enteredNumber);
        }
    }

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber - 1);
        props.onPageChange(pageNumber);
        setEnteredPageNumber('');
    }

    return (
        <Fragment>
            <ReactPaginate
                previousLabel={t('previous')}
                nextLabel={t('next')}
                breakLabel={'...'}
                pageCount={props.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={1}
                onPageChange={(selectedItem) => handlePageChange(selectedItem.selected + 1) }
                containerClassName={'pagination'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                activeClassName={'active'}
                breakClassName={'page-link'}
                breakLinkClassName={'page-item'}
                disabledClassName={'disabled'}
                forcePage={currentPage}
            />
            <Form inline style={{ paddingBottom: "20px" }}>
                <Form.Group style={{ marginLeft: "20px", marginRight: "20px" }}>
                    {t('total')} {props.itemsCount}
                </Form.Group>
                <Form.Group controlId="name">
                    <Form.Label>{t('go to page')}</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder=""
                        autoComplete="off"
                        value={enteredPageNumber}
                        onChange={(event) => validateEnteredPageNumber(event.target.value, props.pageCount)}
                        style={{ width: "60px" }}
                        className="mx-2"
                        data-cy="go-to-page-text-box"
                        aria-label="go-to-page-text-box"
                    />
                </Form.Group>

                <Button
                    className="float-right"
                    variant="primary"
                    onClick={() => handlePageChange(Number(enteredPageNumber)) }
                    data-cy="go-to-page-button"
                >
                    {t('go')}
                </Button>
            </Form>

        </Fragment>

    );
}

export default Pagination;
