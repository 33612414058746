import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import SelectSearch from './SelectSearch';
import projectsCache from '../../containers/DataIngestion/Projects/projectsCache';
import Project from '../../containers/DataIngestion/Projects/interfaces';


interface FilterByProjectNameAndIdProps {
    projectIdByName: string
    setProjectIdByName: (id: string) => void
    projectId: string
    setProjectId: (id: string) => void
}

const FilterByProjectNameAndId = (props: FilterByProjectNameAndIdProps) => {
    const { t } = useTranslation();

    return (
        <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 5 }}>
            <Row noGutters={false} style={{ paddingBottom: 10 }}>
                <Col md="auto" className="my-auto">
                    {t("project name")}
                </Col>
                <Col>
                    <SelectSearch<Project>
                        value={props.projectIdByName}
                        onChange={props.setProjectIdByName}
                        placeholder={""}
                        showClearButton={true}
                        cache={projectsCache}
                        classNamePrefix="project"
                        isDisabled={props.projectId !== ''}
                    />
                </Col>
                <Col md="auto" className="my-auto">
                    {t("projectID")}
                </Col>
                <Col>
                    <SelectSearch<Project>
                        value={props.projectId}
                        onChange={props.setProjectId}
                        placeholder={""}
                        showClearButton={true}
                        idAsLabel={true}
                        cache={projectsCache}
                        classNamePrefix="project-id"
                        isDisabled={props.projectIdByName !== ''}
                    />
                </Col>
            </Row>
        </Container>
    )
}

export default FilterByProjectNameAndId;