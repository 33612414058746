import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap'

const Locale = () => {

  const LANG_CHINESE = "cn"
  const LANG_ENGLISH = "en"

  const [ locale, setLocale ] = useState<string>(LANG_ENGLISH)

  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Nav>
      <Button data-testid="locale-switch" variant="outline-primary" onClick={() => {
        if (locale === LANG_ENGLISH) {
          setLocale(LANG_CHINESE)
          changeLanguage(LANG_CHINESE)
        } else {
          setLocale(LANG_ENGLISH)
          changeLanguage(LANG_ENGLISH)
        }
      }}>{ locale === LANG_ENGLISH ? "Chinese" : "English" }</Button>
    </Nav>
    )
}

export default Locale;
