export const SelectOptions = {
    ForwardToSensorsData: {
        true: "yes",
        false: "no"
    },
    IsOverseas: {
        true: "true",
        false: "false"
    }
}
