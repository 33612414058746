import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import '../../../styles/Table.css';
import { Col, Container, Row } from 'react-bootstrap';
import Project from './interfaces';
import AddProjectButton from './AddProjectButton';
import ProjectRowContainer from './ProjectRowContainer';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/UI/Spinner';
import Pagination from '../../../components/UI/Pagination';
import config from '../../../config/config';
import FilterByProjectNameAndId from '../../../components/Forms/FilterByProjectNameAndId';
import projectsCache from './projectsCache';
import { hasRights } from '../../../auth/RightsUtils'
import { Rights } from '../../../auth/Rights';
import { IfGranted, IfAnyGranted } from 'react-authorization'
import { byDateDescOrdering } from '../../utils';
import { AlertContext, AlertContextInterface } from '../../../context/AlertContext';

const Projects = () => {
    const PAGE_SIZE = config.pagination.PAGE_SIZE;

    const [projectIdByName, setProjectIdByName] = useState('');
    const [projectId, setProjectId] = useState('');
    const [projects, setProjects] = useState<Array<Project>>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [loading, setLoading] = useState(true);
    const [reloadCache, setReloadCache] = useState(false);

    const authContext = useContext(AuthContext);

    const alertContext: AlertContextInterface = useContext(AlertContext);

    const { t } = useTranslation();

    const filterByProjectNameOrId = (projects: Array<Project>, projectIdByName?: string, projectId?: string): Array<Project> => {
        return projects.filter(p => p.id === projectId || p.id === projectIdByName)
    }

    const isFilteringByProjectNameOrId = (projectIdByName?: string, projectId?: string): boolean => {
        return projectIdByName !== '' || projectId !== '';
    }

    useEffect(() => {
        if (authContext.isAuthenticated) {
            projectsCache.reload(setLoading, reloadCache)
                .then((response: Array<Project>) => {
                    const filteredProjects = isFilteringByProjectNameOrId(projectIdByName, projectId)
                        ? filterByProjectNameOrId(response, projectIdByName, projectId)
                        : response

                    if (filteredProjects.length <= PAGE_SIZE) {
                        setCurrentPage(1);
                    }
                    setProjects(filteredProjects
                        .sort(byDateDescOrdering())
                        .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE));
                    setTotalItems(filteredProjects.length);
                    setTotalPages(Math.ceil(filteredProjects.length / PAGE_SIZE));
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(t("error in processing response from server", { error: reason }));
                })
        }
    }, [loading, PAGE_SIZE, currentPage, reloadCache, projectIdByName, projectId, authContext.isAuthenticated]);

    const handleProjectAdded = useCallback(
        (project: Project) => {
            setProjects([
                project,
                ...projects
            ])
            setReloadCache(true);
        },
        [projects],
    );

    const handleProjectDeleted = useCallback(
        () => {
            setLoading(true);
            setReloadCache(true);
        },
        [],
    );

    const handleProjectEdited = useCallback(
        (project: Project) => {
            const newProjects = [...projects];
            const projectIndex = projects.findIndex(p => p.id === project.id);
            newProjects[projectIndex] = project;
            setProjects(newProjects);
            setLoading(true);
            setReloadCache(true);
        },
        [projects],
    );

    const handlePageChange = useCallback((selectedPage: number) => {
        setCurrentPage(selectedPage);
    }, []);

    let content = null;

    if (loading) {
        content = <Spinner />;
    }
    else {
        let table = <Table striped bordered hover responsive className="m-0 sticky-header min-width-75" size="sm">
            <thead>
                <tr>
                    <th>{t('project name')}</th>
                    <th>{t('project type')}</th>
                    <th>{t('userID')}</th>
                    <th>{t('projectID')}</th>
                    <th>{t('description')}</th>
                    <th>{t('to sensors')}</th>
                    <th>{t('update')}</th>
                    <IfGranted expected={Rights.ProjectWrite} actual={authContext.userRights}>
                        <th>{t('edit')}</th>
                    </IfGranted>
                </tr>
            </thead>
            <tbody data-testid="projects-list">
                {
                    projects?.
                        map(p =>
                            <ProjectRowContainer
                                key={p.id}
                                project={p}
                                onProjectDeleted={handleProjectDeleted}
                                onProjectEdited={handleProjectEdited}
                            />)
                }
            </tbody>
        </Table>;

        // calculating table height by substracting combined heights of header (56), top/bottom margins (24+4), page title (33), filtering (95), pagination (optional: 58)
        let elementsHeight = 212;
        if (totalPages > 1) elementsHeight += 58;
        content = <Container fluid className="d-flex pb-3 pt-2 px-0 m-0" style={{ height: `calc(100vh - ${elementsHeight}px)` }}>
            {table}
        </Container>
    }

    return (
        <IfAnyGranted expected={[Rights.ProjectRead, Rights.ProjectWrite]} actual={authContext.userRights}>
            <Fragment>
                <h3>
                    {t('projects')}
                </h3>
                <Container fluid={true} className="px-0">
                    <Row noGutters={true}>
                        <Col>
                            <FilterByProjectNameAndId projectIdByName={projectIdByName} setProjectIdByName={setProjectIdByName} projectId={projectId} setProjectId={setProjectId} />
                        </Col>
                        <Col md="auto" className="ml-5">
                            <AddProjectButton onProjectAdded={handleProjectAdded} disabled={!hasRights(authContext.userRights, Rights.ProjectWrite)} />
                        </Col>
                    </Row>
                </Container>
                {content}
                {
                    totalPages > 1 ?
                        <div className="d-flex justify-content-center" data-testid="pagination">
                            <Pagination
                                pageCount={totalPages}
                                itemsCount={totalItems}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        : null
                }
            </Fragment>
        </IfAnyGranted>
    )
}

export default Projects;