import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const editIcon = (props) => {
    return (
        <FontAwesomeIcon icon={['fas', 'edit']} color="#007bff" className="mr-4" onClick={ props.onClick } />
    );
}

export default editIcon;
