import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';


interface CancelSaveButtonsProps {
    onCancelClick: (event: any) => void
    onSaveClick: (event: any) => void
    configNotChanged: boolean
}

const CancelSaveButtons = (props: CancelSaveButtonsProps) => {

    const { t } = useTranslation();

    return (
        <Container fluid={true} style={{ paddingLeft: 0, paddingRight: 0, width: "150px" }}>
        <Row>
            <Col>
                <Button
                    variant="secondary"
                    onClick={props.onCancelClick}
                    size="sm"
                >
                    {t('cancel')}
                </Button>
            </Col>
            <Col>
                <Button
                    variant="primary"
                    disabled={props.configNotChanged}
                    onClick={props.onSaveClick}
                    size="sm"
                >
                    {t('save')}
                </Button>
            </Col>
        </Row>
        </Container>
    )
}

export default CancelSaveButtons;
