import * as React from 'react';
import { useState, useCallback, useEffect, useContext } from 'react';
import authServiceClient from '../apiClient/AuthServiceClient';
import { getRights } from '../auth/RightsUtils';
import { AlertContext } from './AlertContext';
import { useTranslation } from 'react-i18next';
import { getToken, removeToken, setToken } from '../utils/JwtToken';
import { withRouter } from "react-router-dom";

const REFRESH_TOKEN_TIME_GAP_IN_SECONDS = 30;

function shouldRefreshToken(): boolean {
  //Access token is valid for 15 minutes, but we want to refresh access token 
  //30 seconds (parameter REFRESH_TOKEN_TIME_GAP_IN_SECONDS) before expiration 
  const currentTimePlusTimeGap = Math.floor((Date.now() / 1000) + REFRESH_TOKEN_TIME_GAP_IN_SECONDS);
  const expire_time = localStorage.getItem('expire_time');
  return expire_time === null || currentTimePlusTimeGap > parseInt(expire_time!)
}

export interface AuthenticationResponse {
  access_token: string,
  expire_time: number
}

interface AuthContextInterface {
  login: (user: string, password: string, onSuccessCallback: () => void, onErrorCallback: () => void) => void,
  logout: (onSuccessCallback: () => void) => void,
  reauthenticate: () => void,
  isAuthenticated: boolean,
  user: string,
  authIsLoading: boolean,
  autoSignUpInProgress: boolean,
  userRights: string[],
  accessTokenExpireTime: number,
}

export const AuthContext = React.createContext<AuthContextInterface>({
  login: (user: string, password: string, onSuccessCallback: () => void, onErrorCallback: () => void) => { },
  logout: (onSuccessCallback: () => void) => { },
  reauthenticate: () => { },
  isAuthenticated: false,
  user: '',
  authIsLoading: false,
  autoSignUpInProgress: false,
  userRights: [],
  accessTokenExpireTime: 0,
});

const USER_NAME_KEY = "email";
const EXPIRE_TIME_KEY = "expire_time";



const AuthContextProvider: React.FC = (props: any) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<string>('');
  const [authIsLoading, setAuthIsLoading] = useState(false);
  const [autoSignUpInProgress, setAutoSignUpInProgress] = useState(false);
  const [userRights, setUserRights] = useState<string[]>([])
  const [accessTokenExpireTime, setAccessTokenExpireTime] = useState(0)
  const alertContext = useContext(AlertContext);
  const { t } = useTranslation();

  const updateStateForLoggedInUser = useCallback((email: string, authenticationResponse: AuthenticationResponse) => {
    setUser(email);
    setIsAuthenticated(true);
    setUserRights(getRights(authenticationResponse.access_token))
    setAccessTokenExpireTime(authenticationResponse.expire_time)
    localStorage.setItem(USER_NAME_KEY, email);
    setToken(authenticationResponse.access_token);
    localStorage.setItem(EXPIRE_TIME_KEY, authenticationResponse.expire_time.toString());
  }, []);

  const loginHandler = (email: string, password: string, onSuccessCallback: (authenticationResponse: AuthenticationResponse) => void, onErrorCallback: () => void) => {
    setAuthIsLoading(true);
    validatePassword(
      email,
      password,
      (authenticationResponse) => {
        updateStateForLoggedInUser(email, authenticationResponse);
        setAuthIsLoading(false);
        onSuccessCallback(authenticationResponse);
      },
      () => {
        setAuthIsLoading(false);
        onErrorCallback();
      },
    );
  };

  const updateStateForLogoutUser = useCallback(() => {
    localStorage.removeItem(USER_NAME_KEY);
    localStorage.removeItem(EXPIRE_TIME_KEY);

    removeToken()

    setIsAuthenticated(false);
    setUser('');
    setUserRights([]);
    setAccessTokenExpireTime(0);
  }, []);

  const logoutHandler = useCallback((onSuccessCallback: () => void) => {
    setAuthIsLoading(true);
    authServiceClient.logout()
    updateStateForLogoutUser()
    onSuccessCallback();
    setAuthIsLoading(false);
  }, [updateStateForLogoutUser]);

  const updateStateForReturningUser = useCallback(() => {
    if (shouldRefreshToken()) {
      authServiceClient.refreshToken().then(token => {
        setUserRights(getRights(token.access_token))
        setAccessTokenExpireTime(token.expire_time)
        setToken(token.access_token);
        localStorage.setItem(EXPIRE_TIME_KEY, token.expire_time.toString());
        setIsAuthenticated(true);
      })
        .catch(() => logoutHandler(() => {
          props.history.push("/login");
          alertContext.showErrorAlert(t("session expired"))
        }))
    } else {
      setIsAuthenticated(true);
    }
  }, [logoutHandler]);

  useEffect(() => {
    const email = localStorage.getItem(USER_NAME_KEY);
    const token = getToken();
    if (email && token) {
      setAutoSignUpInProgress(true);
      setUser(email);
      setUserRights(getRights(getToken()))
      updateStateForReturningUser();
      setAutoSignUpInProgress(false);
    }
  }, [updateStateForReturningUser]);

  const validatePassword = useCallback((email: string, password: string, onSuccessCallback: (authenticationResponse: AuthenticationResponse) => void, onErrorCallback: () => void) => {
    authServiceClient.login({ email: email, password })
      .then((response) => {
        onSuccessCallback(response);
      })
      .catch((reason) => {
        alertContext.showErrorAlert(reason.message);
        onErrorCallback()
      })
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login: loginHandler,
        logout: logoutHandler,
        reauthenticate: updateStateForReturningUser,
        isAuthenticated: isAuthenticated,
        user: user,
        authIsLoading: authIsLoading,
        autoSignUpInProgress: autoSignUpInProgress,
        userRights: userRights,
        accessTokenExpireTime: accessTokenExpireTime
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );

};

export default withRouter(AuthContextProvider);