import React from 'react';
import Spinner from 'react-bootstrap/Spinner'

const spinner = () => {
    return (
        <div className="text-center">
            <Spinner animation="border" variant="dark" />
        </div>
    );
}
 
export default spinner;