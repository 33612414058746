import User from './interfaces';
import accessControlManagementApiClient from '../../../apiClient/AccessControlManagementClient';
import { AbstractCacheInstance } from '../../../utils/AbstractCacheInstance';

class UsersCache extends AbstractCacheInstance<User> {

    async reload(
        setLoadingComponent: (value: boolean) => void,
        forceReload: boolean = false
    ): Promise<Array<User>> {
        return await this.get().reload(
            accessControlManagementApiClient,
            accessControlManagementApiClient.getUsers,
            setLoadingComponent,
            forceReload
        );
    }

    getUserByUserId(id: string): User | undefined {
        return this.get().getById(id)
    }

    getUserNameByUserId(userId: string, t: (message: string) => string): string {
        let user = this.getUserByUserId(userId);
        if (user === undefined) {
            return t('user not found');
        }
        return user.name;
    }
}

export default new UsersCache();
