import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { IfGranted } from 'react-authorization'
import { Rights } from '../../auth/Rights';
const MetadataManagement = () => {

    const authContext = useContext(AuthContext);

    return (
        <IfGranted expected={Rights.EventDefinitionRead} actual={authContext.userRights}>
            <h1>TODO - Metadata Management</h1>
        </IfGranted>
    )
}

export default MetadataManagement;
