import config from '../config/config';
import { AuthenticationResponse } from '../context/AuthContext';

interface LoginRequestDTO {
    email: string,
    password: string
}


class AuthServiceClient {

    apiUrl: string

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    async login(loginResquest: LoginRequestDTO): Promise<AuthenticationResponse> {
        const response = await fetch(
            `${this.apiUrl}/login`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginResquest),
                credentials: "include"
            }
        )
        const check = this.checkErrors(response);
        return check as Promise<AuthenticationResponse>
    }

    async refreshToken(): Promise<AuthenticationResponse> {
        const response = await fetch(
            `${this.apiUrl}/refresh_token`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            }
        )
        return this.checkErrors(response) as Promise<AuthenticationResponse>
    }

    async logout(): Promise<any> {
        const response = await fetch(
            `${this.apiUrl}/logout`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                credentials: "include"
            }
        )
        return await response.json();
    }

    private async checkErrors(response: Response): Promise<AuthenticationResponse> {
        const response_json = await response.json();
        if (response.ok) return response_json;
        else throw Error(response_json.detail.exception_message);
    }

}
const instance = new AuthServiceClient(config.api.authConfiguration.BASE_URL);

export default instance;