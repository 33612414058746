import React from 'react';

interface SmallErrorMessageProps {
    show: boolean,
    message: string
}

const SmallErrorMessage = (props: SmallErrorMessageProps) => {
    if(!props.show) return null;
    else return (
        <div className="small text-danger">{ props.message }</div>
    )
}

export default SmallErrorMessage;
