import React, { Fragment, useState, useEffect, useContext, useCallback } from 'react';
import User from "./interfaces"
import Table from 'react-bootstrap/Table';
import '../../../styles/Table.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import UserRowContainer from './UserRowContainer'
import { AuthContext } from '../../../context/AuthContext';
import AddUserButton from './AddUserButton';
import config from '../../../config/config';
import Pagination from '../../../components/UI/Pagination';
import Spinner from '../../../components/UI/Spinner';
import usersCache from './usersCache';
import FilterByUserNameAndAccount from './FilterByUserNameAndAccount';
import { hasRights } from '../../../auth/RightsUtils'
import { Rights } from '../../../auth/Rights';
import { IfGranted, IfAnyGranted } from 'react-authorization'
import { byDateDescOrdering } from '../../utils';
import { AlertContext, AlertContextInterface } from '../../../context/AlertContext';

function getSelectedUserFromCache(userId: string, handleUserDeleted: (id: string) => void, handleUserEdited: (user: User) => void) {
    let rows;
    let user = usersCache.getObjectById(userId);
    if (userId !== '' && user !== undefined) {
        rows =
            <UserRowContainer
                key={userId}
                user={user}
                onUserDeleted={handleUserDeleted}
                onUserEdited={handleUserEdited}
            />
    }
    return rows;
}

const Users = () => {
    const PAGE_SIZE = config.pagination.PAGE_SIZE;

    const [userId, setUserId] = useState('');
    const [users, setUsers] = useState<Array<User>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const [loading, setLoading] = useState(true);
    const [reloadCache, setReloadCache] = useState(false);

    const authContext = useContext(AuthContext);

    const alertContext: AlertContextInterface = useContext(AlertContext);

    const { t } = useTranslation();

    useEffect(() => {
        if (authContext.isAuthenticated) {
            usersCache.reload(setLoading, reloadCache)
                .then((response: Array<User>) => {
                    setUsers(response
                        .sort(byDateDescOrdering())
                        .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE))
                    setTotalItems(usersCache.getObjects().length)
                    setTotalPages(Math.ceil(usersCache.getObjects().length / PAGE_SIZE))
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(t("error in processing response from server", { error: reason }));
                })
        }
    }, [loading, PAGE_SIZE, currentPage, reloadCache, authContext.isAuthenticated]);

    const handleUserAdded = useCallback(
        (user: User) => {
            setUsers([
                user,
                ...users
            ]);
            setReloadCache(true);
            setLoading(true);
        },
        [users],
    );

    const handleUserDeleted = useCallback(
        () => {
            setReloadCache(true);
            setLoading(true);
        },
        [],
    );

    const handleUserEdited = useCallback(
        (user: User) => {
            const newUsers = [...users];
            const userIndex = users.findIndex(u => u.id === user.id);
            newUsers[userIndex] = user;
            setUsers(newUsers);
            setReloadCache(true);
            setLoading(true);
        },
        [users],
    );

    const handlePageChange = useCallback((selectedPage: number) => {
        setCurrentPage(selectedPage);
    }, []);

    let content = null;

    if (loading) {
        content = <Spinner />;
    }
    else {
        // If select/search box is used, read user from cache rather than from list of users on current page.
        let rows = getSelectedUserFromCache(userId, handleUserDeleted, handleUserEdited);
        if (rows === undefined) {
            rows =
                <Fragment>
                    {
                        users?.
                            filter(u => userId === '' || u.id === userId).
                            map(u =>
                                <UserRowContainer
                                    key={u.id}
                                    user={u}
                                    onUserDeleted={handleUserDeleted}
                                    onUserEdited={handleUserEdited}
                                />
                            )
                    }
                </Fragment>
        }

        content = <Table striped bordered hover className="m-0 sticky-header min-width-75" size="sm">
            <thead>
                <tr>
                    <th>{t('name')}</th>
                    <th>{t('userID')}</th>
                    <th>{t('mail')}</th>
                    <th>{t('description')}</th>
                    <th>{t('role type')}</th>
                    <th>{t('update')}</th>
                    <IfGranted expected={Rights.UserWrite} actual={authContext.userRights}>
                        <th>{t('edit')}</th>
                    </IfGranted>
                </tr>
            </thead>
            <tbody data-testid="users-list">
                {rows}
            </tbody>
        </Table>
    }

    return (
        <IfAnyGranted expected={[Rights.UserRead, Rights.UserWrite]} actual={authContext.userRights}>
            <Fragment>
                <h3>
                    {t('users')}
                </h3>
                <Container fluid={true} className="px-0">
                    <Row noGutters={true}>
                        <Col>
                            <FilterByUserNameAndAccount setUserId={setUserId} userId={userId} />
                        </Col>
                        <Col md="auto" className="ml-5">
                            <AddUserButton onUserAdded={handleUserAdded} disabled={!hasRights(authContext.userRights, Rights.UserWrite)} />
                        </Col>
                    </Row>
                </Container>
                {content}
                {
                    totalPages > 1 ?
                        <div className="d-flex justify-content-center" data-testid="pagination">
                            <Pagination
                                pageCount={totalPages}
                                itemsCount={totalItems}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        : null
                }
            </Fragment>
        </IfAnyGranted>
    )
}

export default Users;