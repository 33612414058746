import { FunnelDataRecord, BehaviorDataRecord, Option, BehaviorPlotData } from "./interfaces";

type DataRecord = FunnelDataRecord | BehaviorDataRecord;

function cleanAppEventLabel(label: string): string {
    const classNameAsLabel = label.split('.')?.pop() || "";

    const controllerPattern = /^([a-zA-Z0-9]+)(Controller)$/;
    const labelWithoutController = classNameAsLabel.replace(controllerPattern, '$1');

    return labelWithoutController;
}

function cleanAppIdLabel(label: string): string {
    const tldPattern = /^(com|org)/;
    const reverseDomainRemovedLabel =  tldPattern.test(label) ? label.split('.')?.pop() || "" : label;

    return reverseDomainRemovedLabel;
}

export function getAppId(response: DataRecord[]): Option[] {
    return response
        .map((d) => d.app_id)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
        .map((v) => ({ value: v, label: cleanAppIdLabel(v) }));
}

export function getFunnelAppEvents(funnelData: FunnelDataRecord[], appId: Option, step: number = -1): Option[] {
    return funnelData.filter(d => d.app_id === appId.value)
        .flatMap((d) => [d.source_step, d.target_step])
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
        .map((v) => ({ value: v, label: cleanAppEventLabel(v) }));
}

export function getBehaviorAppEvents(behaviorData: BehaviorDataRecord[], appId: Option, step: number = -1): Option[] {
    let data = behaviorData.filter(d => d.app_id === appId.value)

    if (step > 0)
        data = data.filter(d => (d as BehaviorDataRecord).step_index === step);
    
    return data.flatMap((d) => step === 1 ? d.source_step : d.target_step)
        .filter((value, index, self) => self.indexOf(value) === index)
        .sort()
        .map((v) => ({ value: v, label: cleanAppEventLabel(v) }));
}

export function getFunnelPlotData(funnelData: FunnelDataRecord[], appId: Option, appEvents: Option[]): Option[] {
    const data = funnelData.filter((d) => d.app_id === appId.value);

    return appEvents?.map((option: Option, i: number) => {
        if (i === 0) {
            const edge = data.filter((d) => d.source_step === option.value)
                .reduce((sum, d) => sum + d.sum_count, 0);
            return { label: option.label, value: edge };
        } else {
            const sourceStep = appEvents[i - 1].value;
            const targetStep = option.value;
            const edge = data
                .filter((d) => (d.source_step === sourceStep) && (d.target_step === targetStep))
                .reduce((sum, d) => sum + d.sum_count, 0);
            return { label: option.label, value: +edge };
        }
    });
}

function filterFirstStep(behaviorData: BehaviorDataRecord[], firstStep: string, stepIndex: number, maxStepIndex: number): BehaviorDataRecord[] {
    let result = behaviorData.filter((d) => (d.step_index === stepIndex) && (d.source_step === firstStep));

    if (stepIndex >= maxStepIndex)
        return result;
    
    if (result.length === 0)
        return result;

    const newResult:BehaviorDataRecord[] = [];
    result.forEach((d) => {
        newResult.push(d);
        newResult.push(...filterFirstStep(behaviorData, d.target_step, stepIndex + 1, maxStepIndex));
    });
    
    // Deduplicate in case of cycles in graph
    return newResult.filter((v, i, a) => a.indexOf(v) === i);
}

function filterLastStep(behaviorData: BehaviorDataRecord[], lastStep: string, stepIndex: number): BehaviorDataRecord[] {
    if(stepIndex === 1)
        return behaviorData.filter((d) => (d.target_step === lastStep && d.step_index === 1));
        
    let result = behaviorData.filter((d) => (d.step_index < stepIndex) || (d.target_step === lastStep));

    const newResult:BehaviorDataRecord[] = [];
    result
        .filter(d => d.step_index === stepIndex)
        .forEach((d) => {
            newResult.push(...filterLastStep(result, d.source_step, stepIndex - 1));
            newResult.push(d);
        });
    
    // Deduplicate in case of cycles in graph
    return newResult.filter((v, i, a) => a.indexOf(v) === i);
}

export function getBehaviorPlotData(
    behaviorData: BehaviorDataRecord[], 
    appId: Option, 
    steps: number = 5,
    firstStep: Option = {label: "", value: ""},
    lastStep: Option = {label: "", value: ""},
): BehaviorPlotData {
    let data = behaviorData.filter((d) => d.app_id === appId.value);

    if (lastStep != null && lastStep.value !== "") {
        const lastStepValue = lastStep.value as string;
        const lastStepIndexes = data
            .filter((d) => d.step_index <= steps)
            .filter((d) => d.target_step === lastStepValue)
            .map((d) => d.step_index)
        if (lastStepIndexes.length !== 0)
        {
            const lastStepIndex = lastStepIndexes.reduce((p, v) => ( p > v ? p : v ));
            data = filterLastStep(data, lastStepValue, lastStepIndex);
        } else data = [];
    }        

    if (firstStep != null && firstStep.value !== "") {
        data = filterFirstStep(data, firstStep.value as string, 1, steps).sort();
    }

    let label: string[][] = [];
    let source: number[][] = [];
    let target: number[][] = [];
    let value: number[][] = [];
    let prev_len = 0;
    for (let step = 1; step <= steps; step++) {
        let current = data.filter((d) => d.step_index === (step));
        
        if (step === 1) {            
            const stage = current.map((d) => d.source_step)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort();          

            label.push(stage);
        }

        label.push(
            current.map((d) => d.target_step)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort()
        );
        const next_len = prev_len + label[step-1].length;
        const previous_len = prev_len;
        source.push(current.map((d, prev_len) => (label[step-1].indexOf(d.source_step) + previous_len)));
        target.push(current.map((d) => (label[step].indexOf(d.target_step) + next_len)));
        value.push(current.map((d) => (d.sum_count)));

        
        prev_len = next_len;
    }

    return {
        label: label.flat().map((l) => cleanAppEventLabel(l)),
        link : {
            source: source.flat(),
            target: target.flat(),
            value: value.flat(),
        }
    }
}
