const MOBILE_AUTO_TRACKING_OPTIONS = [
    { value: '1', label: 'AppStart' },
    { value: '2', label: 'AppEnd' },
    { value: '4', label: 'AppClick' },
    { value: '8', label: 'AppViewScreen' },
];


const WEB_AUTO_TRACKING_OPTIONS = [
    { value: '1', label: 'PageView' },
    { value: '2', label: 'WebClick' }
];

interface ValueWithLabel {
    value: string,
    label: string,
}

class AutoTrackingOptions {

    trackingOptions: Array<ValueWithLabel>

    constructor(trackingOptions: Array<ValueWithLabel>) {
        this.trackingOptions = trackingOptions
    }

    getTrackingOptions(): Array<ValueWithLabel> {
        return this.trackingOptions;
    }

    isAutoTrackingOptionSelected(autoTrackMode: number, option: number): boolean {
        if (autoTrackMode === -1) return false;
        return (option & autoTrackMode) !== 0;
    }

    convertNumberToAutoTrackingOptions(value: number): Array<ValueWithLabel> {
        return this.trackingOptions.filter(o => this.isAutoTrackingOptionSelected(value, +o.value));
    }

    convertNumberToAutoTrackingOptionsLabels(value: number): Array<string> {
        const valueWithLabels = this.convertNumberToAutoTrackingOptions(value)
        return valueWithLabels.map(valueWithLabel => valueWithLabel.label)
    }

    calculateAutoTrackingMode(selectedOptions: Array<any>): number {
        if (selectedOptions === null || selectedOptions.length === 0) return 0;
        return selectedOptions.map((o: any) => +o.value).reduce((a: number, b: number) => a | b)
    }
}

const mobileAutoTrackingOptions = new AutoTrackingOptions(MOBILE_AUTO_TRACKING_OPTIONS);
const webAutoTrackingOptions = new AutoTrackingOptions(WEB_AUTO_TRACKING_OPTIONS);

export {
    AutoTrackingOptions,
    mobileAutoTrackingOptions,
    webAutoTrackingOptions
};
