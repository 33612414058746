import config from '../config/config';
import Role from '../containers/AccessManagement/Roles/interfaces';
import User from '../containers/AccessManagement/Users/interfaces';
import { getToken } from '../utils/JwtToken';


interface CreateUserRequestDTO {
    name: string
    email: string
    description: string
    roleId: string
}

interface UpdateUserRequestDTO {
    name: string
    email: string
    description: string
    roleId: string
}

interface CreateRoleRequestDTO {
    type: string
    description: string
    rights: string []
}

interface UpdateRoleRequestDTO {
    type: string
    description: string
    rights: string []
}

interface PageResultWithUsersDTO {
    total_count: number
    items: Array<User>
}

interface PageResultWithRolesDTO {
    total_count: number
    items: Array<Role>
}


class AccessControlManagementApiClient {

    apiUrl: string

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    async getUsers(page: number, page_size: number): Promise<PageResultWithUsersDTO> {
        
        const response = await fetch(
            `${this.apiUrl}/users?page=${page}&page_size=${page_size}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getToken()}`
                }
            }
        );
        return await response.json();
    }

    async addUser(user: CreateUserRequestDTO): Promise<User> {
        const response = await fetch(
            `${this.apiUrl}/users`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getToken()}`
                },
                body: JSON.stringify(user)
            }
        )
        return this.checkErrors(response) as Promise<User>
    }

    async deleteUser(id: string): Promise<any> {
        const response = await fetch(
            `${this.apiUrl}/users/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getToken()}`
                }
            }
        );
        return await response.json();
    }

    async updateUser(id: string, updateUserRequestDTO: UpdateUserRequestDTO): Promise<User> {
        const response = await fetch(
            `${this.apiUrl}/users/${id}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getToken()}`
                },
                body: JSON.stringify(updateUserRequestDTO)
            }
        );
        return this.checkErrors(response) as Promise<User>
    }


    async addRole(role: CreateRoleRequestDTO): Promise<Role> {
        const response = await fetch(
            `${this.apiUrl}/roles`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getToken()}`
                },
                body: JSON.stringify(role)
            }
        )
        return this.checkErrors(response) as Promise<Role>
    }

    async getRoles(page: number, page_size: number): Promise<PageResultWithRolesDTO> {
        const response = await fetch(
            `${this.apiUrl}/roles?page=${page}&page_size=${page_size}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getToken()}`
                }
            }
        );
        return await response.json();
    }


    async updateRole(id: string, updateRoleRequestDTO: UpdateRoleRequestDTO): Promise<Role> {
        const response = await fetch(
            `${this.apiUrl}/roles/${id}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getToken()}`
                },
                body: JSON.stringify(updateRoleRequestDTO)
            }
        );
        return this.checkErrors(response) as Promise<Role>
    }

    async deleteRole(id: string): Promise<any> {
        const response = await fetch(
            `${this.apiUrl}/roles/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getToken()}`
                }
            }
        );
        return await response.json();
    }


    private async checkErrors(response: Response): Promise<User|Role> {
        const response_json = await response.json();
        if (response.ok) return response_json;
        else throw Error(response_json.detail.exception_message);
    }
}

const instance = new AccessControlManagementApiClient(config.api.accessControlManagementConfiguration.BASE_URL);

export default instance;
