import React, { Fragment } from 'react';
import Tag from '../../../components/UI/Tag';
import { Rights } from '../../../auth/Rights';
import { $enum } from "ts-enum-util";

interface RightsDisplayProps {
    value: string[]
}

const RightsDisplay = (props: RightsDisplayProps) => {

    return (
        <Fragment>
            {
                props.value.map(right => {
                    const rightValue = $enum(Rights).getKeyOrDefault(right, "");
                    return <Tag key={rightValue} tag={rightValue} />;
                })
            }
        </Fragment>
    )
}

export default RightsDisplay;
