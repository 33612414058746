import React, { Fragment, useState, useEffect, useCallback, useContext } from 'react';
import Table from 'react-bootstrap/Table';
import '../../../styles/Table.css';
import Role from './interfaces'
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import RoleRowContainer from './RoleRowContainer'
import AddRoleButton from './AddRoleButton'
import config from '../../../config/config';
import Pagination from '../../../components/UI/Pagination';
import Spinner from '../../../components/UI/Spinner';
import { hasRights } from '../../../auth/RightsUtils'
import { Rights } from '../../../auth/Rights';
import { IfGranted, IfAnyGranted } from 'react-authorization'
import rolesCache from './rolesCache';
import { byDateDescOrdering } from '../../utils';
import { AlertContext, AlertContextInterface } from '../../../context/AlertContext';


const Roles = () => {
    const authContext = useContext(AuthContext);

    const PAGE_SIZE = config.pagination.PAGE_SIZE;

    const [roles, setRoles] = useState<Array<Role>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [loading, setLoading] = useState(true);
    const [reloadCache, setReloadCache] = useState(false);

    const alertContext: AlertContextInterface = useContext(AlertContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (authContext.isAuthenticated) {
            rolesCache.reload(setLoading, reloadCache)
                .then((response: Array<Role>) => {
                    setRoles(response
                        .sort(byDateDescOrdering())
                        .slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE))
                    setTotalItems(rolesCache.getObjects().length)
                    setTotalPages(Math.ceil(rolesCache.getObjects().length / PAGE_SIZE))
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(t("error in processing response from server", { error: reason }));
                })
        }
    }, [loading, PAGE_SIZE, currentPage, reloadCache, authContext.isAuthenticated]);

    const handleRoleAdded = useCallback(
        (role: Role) => {
            setRoles([
                role,
                ...roles
            ]);
            setLoading(true);
            setReloadCache(true);
        },
        [roles],
    );

    const handleRoleDeleted = useCallback(
        () => {
            setLoading(true);
            setReloadCache(true);
        },
        [],
    );

    const handleRoleEdited = useCallback(
        (role: Role) => {
            const newRoles = [...roles];
            const roleIndex = roles.findIndex(r => r.id === role.id);
            newRoles[roleIndex] = role;
            setRoles(newRoles);
            setLoading(true);
            setReloadCache(true);
        },
        [roles],
    );

    const handlePageChange = useCallback((selectedPage: number) => {
        setCurrentPage(selectedPage);
    }, []);


    let content = null;

    if (loading) {
        content = <Spinner />;
    }
    else {
        content = <Table striped bordered hover className="m-0 sticky-header min-width-75" size="sm">
            <thead>
                <tr>
                    <th>{t('role type')}</th>
                    <th>{t('description')}</th>
                    <th>{t('rights')}</th>
                    <th>{t('update')}</th>
                    <IfGranted expected={Rights.RoleWrite} actual={authContext.userRights}>
                        <th>{t('edit')}</th>
                    </IfGranted>
                </tr>
            </thead>
            <tbody data-testid="roles-list">
                {
                    roles?.map(r =>
                        <RoleRowContainer
                            key={r.id}
                            role={r}
                            onRoleDeleted={handleRoleDeleted}
                            onRoleEdited={handleRoleEdited}
                        />
                    )
                }
            </tbody>
        </Table>
    }

    return (
        <IfAnyGranted expected={[Rights.RoleRead, Rights.RoleWrite]} actual={authContext.userRights}>
            <Fragment>
                <h3>
                    {t('roles')}
                    <AddRoleButton onRoleAdded={handleRoleAdded} disabled={!hasRights(authContext.userRights, Rights.RoleWrite)} />
                </h3>
                {content}
                {
                    totalPages > 1 ?
                        <div className="d-flex justify-content-center" data-testid="pagination">
                            <Pagination
                                pageCount={totalPages}
                                itemsCount={totalItems}
                                onPageChange={handlePageChange}
                            />
                        </div>
                        : null
                }
            </Fragment>
        </IfAnyGranted>
    )
}

export default Roles;
