import config from '../config/config';
import Project from '../containers/DataIngestion/Projects/interfaces';
import ProjectsSdkConfig from '../containers/DataIngestion/SdkConfiguration/interfaces';
import BaseInfo from '../containers/interfaces'
import { getToken } from '../utils/JwtToken';

interface CreateProjectRequestDTO {
    name: string
    description: string
    projectType: string
    userId: string
    appName: string
    appPackageName: string
    isOverseas: boolean
    forwardToSensorsData: boolean
}

interface UpdateProjectRequestDTO {
    name: string
    description: string
    projectType: string
    userId: string
    forwardToSensorsData: boolean
}

interface CreateProjectSdkRequestDTO {
    projectId: string
    autoTrackMode: number
    enableJavaScriptBridge: boolean
    flushInterval: number
    flushBulkSize: number
    singleFlushSize: number
    maxCacheSize: number
    disableSDK: boolean
}

interface UpdateProjectSdkRequestDTO {
    projectId: string
    autoTrackMode: number
    enableJavaScriptBridge: boolean
    flushInterval: number
    flushBulkSize: number
    singleFlushSize: number
    maxCacheSize: number
    disableSDK: boolean
}

export interface HasId {
    id: string
}

export interface HasIdName {
    id: string
    name: string
}

export interface GenericPageResult<T extends HasId> {
    total_count: number
    items: Array<T>
}

interface PageResultWithProjectsSdkConfigDTO {
    total_count: number
    items: Array<ProjectsSdkConfig>
}

class TrackingConfigurationApiClient {

    baseURL: string

    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    async getProjectsBaseInformation(
        page: number,
        page_size: number,
        project_id: string | null = null,
        user_id: string | null = null
    ): Promise<GenericPageResult<Project>> {
        let url = `${this.baseURL}/projects?${this.getUrlParamsString(page, page_size, project_id, user_id)}`;

        const response = await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        );
        return await response.json();
    }

    async getSdkConfigInformation(
        page: number,
        page_size: number,
        project_id: string | null = null
    ): Promise<PageResultWithProjectsSdkConfigDTO> {
        let url = `${this.baseURL}/projects/config/sdk?${this.getUrlParamsString(page, page_size, project_id)}`;

        const response = await fetch(
            url,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        );
        return await response.json();
    }

    async addProject(project: CreateProjectRequestDTO): Promise<Project> {
        const response = await fetch(
            `${this.baseURL}/projects`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(project)
            }
        )
        return this.checkErrors(response) as Promise<Project>
    }

    async addSdkConfig(projectSdk: CreateProjectSdkRequestDTO): Promise<ProjectsSdkConfig> {
        const response = await fetch(
            `${this.baseURL}/projects/config/sdk`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(projectSdk)
            }
        )
        return this.checkErrors(response) as Promise<ProjectsSdkConfig>
    }


    async updateProject(id: string, updateProjectRequestDTO: UpdateProjectRequestDTO): Promise<Project> {
        const response = await fetch(
            `${this.baseURL}/projects/${id}`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(updateProjectRequestDTO)
            }
        );
        return this.checkErrors(response) as Promise<Project>
    }

    async updateSdkConfig(id: string, updateProjectSdkRequestDTO: UpdateProjectSdkRequestDTO): Promise<ProjectsSdkConfig> {
        const response = await fetch(
            `${this.baseURL}/projects/${id}/config/sdk`,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                },
                body: JSON.stringify(updateProjectSdkRequestDTO)
            }
        );
        return await response.json();
    }

    async deleteProject(id: string): Promise<any> {
        const response = await fetch(
            `${this.baseURL}/projects/${id}`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getToken()}`
                }
            }
        );
        return await response.json();
    }

    private async checkErrors(response: Response): Promise<BaseInfo> {
        const response_json = await response.json();
        if (response.ok) return response_json;
        else throw Error(response_json.detail.exception_message);
    }

    private getUrlParamsString(
        page: number,
        page_size: number,
        project_id: string | null = null,
        user_id: string | null = null
    ) {
        const urlParams = new URLSearchParams();
        urlParams.set("page", String(page));
        urlParams.set("page_size", String(page_size));
        if (project_id) {
            urlParams.set("project_id", project_id);
        }
        if (user_id) {
            urlParams.set("user_id", user_id);
        }
        return urlParams.toString();
    }
}

const instance = new TrackingConfigurationApiClient(config.api.trackingConfiguration.BASE_URL);

export default instance;
