import React, { useState, useCallback } from 'react';
import ProjectsSdkConfig from './interfaces';
import ProjectSdkRow from './ProjectSdkRow';
import EditProjectSdkForm from './EditProjectSdkForm';

interface ProjectSdkRowContainerProps {
    sdkConfig: ProjectsSdkConfig,
    onProjectEdited: (project: ProjectsSdkConfig) => void,
}

const ProjectSdkRowContainer = (props: ProjectSdkRowContainerProps) => {
    const sdkConfig = props.sdkConfig;

    const [editing, setEditing] = useState(false);

    let projectDisplay;

    const handleProjectEdited = useCallback(
        (project: ProjectsSdkConfig) => {
            props.onProjectEdited(project);
            setEditing(false);
        },
        [props],
    );

    if(editing) {
        projectDisplay =
            <EditProjectSdkForm
                sdkConfig={ sdkConfig }
                onCancelClick={ () => setEditing(false) }
                onProjectEdited={ handleProjectEdited }
            />;
    }
    else {
        projectDisplay =
            <ProjectSdkRow
                sdkConfig={ sdkConfig }
                onEditClick={ (_: any) => setEditing(true) }
            />;
    }

    return (<tr>{ projectDisplay }</tr>);
}

export default ProjectSdkRowContainer;
