import rolesCache from '../Roles/rolesCache';
import Role from '../Roles/interfaces';
import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from '../../../components/UI/Spinner';

interface RoleByRoleIdProps {
    roleId: string,
    getAttrFunc: (p: Role|undefined, t: (msg: string) => string) => string
}

export const getRoleType = (p: Role|undefined, t: (msg: string) => string) => {
    if (p === undefined) {
        return t("role not found");
    }
    return p.type;
}

export const RoleByRoleId = (props: RoleByRoleIdProps) => {
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();

    useEffect(() => {
        rolesCache.reload(setLoading);
    }, [loading]);

    let content = null;

    if (loading) {
        content = <Spinner />;
    }
    else {
        let userAttribute = props.getAttrFunc(rolesCache.getRoleByRoleId(props.roleId), t);
        content = <div>{ userAttribute }</div>
    }

    return (
        <Fragment>
            { content }
        </Fragment>
    )
}
