import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import User from './interfaces';
import AddUserForm from './AddUserForm';
import { useTranslation } from 'react-i18next';


interface AddUserButtonProps {
    onUserAdded: (user: User) => void
    disabled: boolean
}

const AddUserButton = (props: AddUserButtonProps) => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const handleCloseAddUserModal = () => setShowAddUserModal(false);
    const handleShowAddUserModal = () => setShowAddUserModal(true);

    const { t } = useTranslation();

    if (showAddUserModal) {
        return (
            <AddUserForm
                onUserAdded={props.onUserAdded}
                handleCloseAddUserModal={handleCloseAddUserModal}
                showAddUserModal={showAddUserModal}
            />
        )
    } else {
        return (
            <Button className="float-right" variant="primary" onClick={handleShowAddUserModal} disabled={props.disabled} data-cy="add-user-button">
                { t('add user')}
            </Button>
        )
    }
}

export default AddUserButton;
