import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { IfGranted } from 'react-authorization'
import { Rights } from '../../auth/Rights';
import {useTranslation} from "react-i18next";

const Dashboard = () => {

    const authContext = useContext(AuthContext);

    const { t } = useTranslation();

    return (
        <IfGranted expected={Rights.MainDashboardRead} actual={authContext.userRights}>
            <h1>TODO - {t("dashboard")}</h1>
        </IfGranted>
    )
}

export default Dashboard;