import React from 'react';

const moment = require('moment');
const momentTimezone = require('moment-timezone');

interface DateTimeTzProps {
    utcDateTime: string
}

const DateTimeInTimezone = (props: DateTimeTzProps) => {
    var tz = momentTimezone.tz.guess();
    const dateTime = moment(props.utcDateTime).tz(tz).format('YYYY-MM-DD HH:mm:ss')

    return (
        <span data-testid="datetime-in-timezone">{ dateTime }</span>
    )
}

export default DateTimeInTimezone;