
import * as React from 'react';
import { useContext } from 'react';
import { AlertContext } from '../../context/AlertContext';
import Alert from 'react-bootstrap/Alert';

const AlertMessage = () => {
    const alertContext = useContext(AlertContext);

    if(alertContext.isAlertVisible()) {
        return (
            <Alert className="mt-3" variant={ alertContext.getAlert().variant } onClose={ () => alertContext.clearAlert() } dismissible >
                { alertContext.getAlert().message }
            </Alert>
        );
    }
    return null;
};

export default AlertMessage;
