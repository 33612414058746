import React, { Fragment, useState, useCallback, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import Project from './interfaces';
import trackingConfigurationApiClient from '../../../apiClient/TrackingConfigurationClient';
import SmallErrorMessage from '../../../components/Forms/SmallErrorMessage';
import { AlertContext } from '../../../context/AlertContext';
import ProjectTypeSelect from '../components/ProjectTypeSelect';
import { useTranslation } from 'react-i18next';
import { SelectOptions } from './fixtures';
import SelectSearch from '../../../components/Forms/SelectSearch';
import usersCache from '../../AccessManagement/Users/usersCache';
import User from '../../AccessManagement/Users/interfaces';
import CancelSaveButtons from '../../../components/Forms/CancelSaveButtons';

interface EditProjectFormProps {
    project: Project,
    onCancelClick: (event: any) => void,
    onProjectEdited: (project: Project) => void,
}

const EditProjectForm = (props: EditProjectFormProps) => {
    const project = props.project;

    const alertContext = useContext(AlertContext);

    const [name, setName] = useState(project.name);
    const [description, setDescription] = useState(project.description);
    const [userId, setUserId] = useState(project.userId);
    const [projectType, setProjectType] = useState(project.projectType);
    const [forwardToSensorsData, setForwardToSensorsData] = useState(project.forwardToSensorsData);

    const [nameIsInvalid, setNameIsInvalid] = useState(false);
    const [descriptionIsInvalid, setDescriptionIsInvalid] = useState(false);
    const [userIdIsInvalid, setUserIdIsInvalid] = useState(false);
    const [projectTypeIsInvalid, setProjectTypeIsInvalid] = useState(false);

    let configNotChanged = false;

    const { t } = useTranslation();

    if (project.name === name
        && project.description === description
        && project.userId === userId
        && project.projectType === projectType
        && project.forwardToSensorsData === forwardToSensorsData) {
        configNotChanged = true
    }

    const handleSaveButtonClick = useCallback((event: any) => {
        event.preventDefault();

        let formIsValid = true;

        if (name.trim() === '') {
            setNameIsInvalid(true);
            formIsValid = false;
        }
        else {
            setNameIsInvalid(false);
        }

        if (description.trim() === '') {
            setDescriptionIsInvalid(true);
            formIsValid = false;
        }
        else {
            setDescriptionIsInvalid(false);
        }

        if (userId.trim() === '') {
            setUserIdIsInvalid(true);
            formIsValid = false;
        }
        else {
            setUserIdIsInvalid(false);
        }

        if (projectType.trim() === '') {
            setProjectTypeIsInvalid(true);
            formIsValid = false;
        }
        else {
            setProjectTypeIsInvalid(false);
        }

        if (formIsValid) {
            const updateProjectRequest = {
                name: name,
                description: description,
                projectType: projectType,
                userId: userId,
                forwardToSensorsData: forwardToSensorsData
            };
            trackingConfigurationApiClient.updateProject(project.id, updateProjectRequest)
                .then((response: Project) => {
                    props.onProjectEdited(response);
                    alertContext.showSuccessAlert(t('Updated project', { name: name }));
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(reason.message);
                });
        }
        else {
            alertContext.showErrorAlert(t("provide all mandatory fields"));
        }
    }, [props, project, name, description, userId, projectType, forwardToSensorsData]);

    return (
        <Fragment>
            <td>
                <Form.Control
                    type="text"
                    placeholder={t("enter project name")}
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    data-cy="project-name"
                    style={{width: "250px"}}
                    autoComplete="off"
                />
                <SmallErrorMessage show={nameIsInvalid} message={t("project name can't be empty")} />
            </td>
            <td>
                <ProjectTypeSelect
                    value={projectType}
                    onChange={setProjectType}
                />
                <SmallErrorMessage show={projectTypeIsInvalid} message={t("choose project type")} />
            </td>
            <td>
                <div style={{width: "280px"}}>
                    <SelectSearch<User>
                            value={userId}
                            onChange={setUserId}
                            placeholder={t("enter user name")}
                            cache={usersCache}
                            idAsLabel={true}
                            classNamePrefix="user"
                    />
                </div>
                <SmallErrorMessage show={userIdIsInvalid} message={t("user name can't be empty")} />
            </td>
            <td>
                {project.id}
            </td>
            <td>
                <Form.Control
                    type="text"
                    placeholder={t("enter project description")}
                    value={description}
                    onChange={(event) => setDescription(event.target.value)}
                    data-cy="description"
                    style={{width: "200px"}}
                    autoComplete="off"
                />
                <SmallErrorMessage show={descriptionIsInvalid} message={t("project description can't be empty")} />
            </td>
            <td>
            <Form.Control
                    as="select"
                    type="text"
                    value={String(forwardToSensorsData)}
                    onChange={(event) => setForwardToSensorsData(JSON.parse(event.target.value))}
                    data-cy="forward-to-sensors-data-select"
            >
                    <option value="false">{SelectOptions.ForwardToSensorsData.false}</option>
                    <option value="true">{SelectOptions.ForwardToSensorsData.true}</option>
                </Form.Control>
            </td>
            <td>
                <Form.Control
                    readOnly
                    defaultValue={project.updateTime}
                />
            </td>
            <td>
                <CancelSaveButtons
                    onCancelClick={props.onCancelClick}
                    onSaveClick={handleSaveButtonClick}
                    configNotChanged={configNotChanged}
                />
            </td>
        </Fragment>
    );
}

export default EditProjectForm;
