import React, { useState, useCallback } from 'react';
import Project from './interfaces';
import ProjectRow from './ProjectRow';
import EditProjectForm from './EditProjectForm';

interface ProjectRowContainerProps {
    project: Project,
    onProjectDeleted: (projectId: string) => void,
    onProjectEdited: (project: Project) => void,
}

const ProjectRowContainer = (props: ProjectRowContainerProps) => {
    const project = props.project;

    const [editing, setEditing] = useState(false);

    let projectDisplay;

    const handleProjectEdited = useCallback(
        (project: Project) => {
            props.onProjectEdited(project);
            setEditing(false);
        },
        [props],
    );

    if(editing) {
        projectDisplay =
            <EditProjectForm
                project={ project }
                onCancelClick={ () => setEditing(false) }
                onProjectEdited={ handleProjectEdited }
            />
    }
    else {
        projectDisplay =
            <ProjectRow
                project={ project }
                onEditClick={ (_: any) => setEditing(true) }
                onProjectDeleted={ props.onProjectDeleted }
            />;
    }
    return (<tr>{ projectDisplay }</tr>);
}

export default ProjectRowContainer;
