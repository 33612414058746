import React, { Fragment } from 'react';
import Tag from '../../../components/UI/Tag';
import { AutoTrackingOptions } from './AutoTrackingMode';

interface AutoTrackingModeDisplayProps {
    value: number,
    autoTrackingOptions: AutoTrackingOptions
}

const AutoTrackingModeDisplay = (props: AutoTrackingModeDisplayProps) => {
    return (
        <Fragment>
            {
                props.autoTrackingOptions.convertNumberToAutoTrackingOptions(props.value).map(o => <Tag key={ o.value } tag={ o.label } />)
            }
        </Fragment>
    )
}

export default AutoTrackingModeDisplay;
