import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { Col, Row, Tabs, Tab, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';
import Select from 'react-select';
import moment from 'moment';
import userBehaviorDataApiClient from '../../apiClient/UserBehaviorDataClient';
import { DatePicker, DateRange } from '../../components/UI/DatePicker';
import { Placeholder } from '../../components/UI/Placeholder';
import { AlertContext } from '../../context/AlertContext';
import { AuthContext } from '../../context/AuthContext';
import { getAppId, getFunnelAppEvents, getFunnelPlotData } from './analysis';
import { FunnelDataRecord, Option } from './interfaces';
import { Rights } from '../../auth/Rights';
import { IfGranted } from 'react-authorization'

const defaultDateRange: DateRange = { startDate: moment().subtract(30, "day"), endDate: moment() };
const defaultSelectedApp = { value: "com.tcl.tclplus", label: "tclplus" };
const defaultSelectedEvents = [{ value: "HomeViewController", label: "HomeView" }, { value: "DeviceRNViewController", label: "DeviceRNView" }];

const Funnel = () => {

    const [dateRange, setDateRange] = useState<DateRange>({ startDate: null, endDate: null });
    const [funnelData, setFunnelData] = useState<Array<FunnelDataRecord>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [ready, setReady] = useState(false);
    const [appIds, setAppIds] = useState<Array<Option>>([]);
    const [appId, setAppId] = useState<Option | null>(null);
    const [appEvents, setAppEvents] = useState<Array<Option>>([]);
    const [appEventsSelected, setAppEventsSelected] = useState<Array<Option>>([]);
    const [funnelPlotData, setFunnelPlotData] = useState<Array<Option>>([{ label: "", value: "" }]);

    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);

    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        setDateRange(defaultDateRange);
        if (authContext.isAuthenticated) {
            userBehaviorDataApiClient.getFunnelData(defaultDateRange)
                .then((response: Array<FunnelDataRecord>) => {
                    setFunnelData(response);
                    const appIds: Option[] = getAppId(response);
                    setAppIds(appIds);
                    setAppId(defaultSelectedApp);
                    const defaultAppEvents = getFunnelAppEvents(response, defaultSelectedApp);
                    setAppEvents(defaultAppEvents);
                    setAppEventsSelected(defaultSelectedEvents);
                    const funnelPlotData = getFunnelPlotData(response, defaultSelectedApp as Option, defaultSelectedEvents);
                    setFunnelPlotData(funnelPlotData);
                    setReady(true);
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(reason.message);
                }).finally(() => { setLoading(false) });
        }
    }, [authContext.isAuthenticated, alertContext]);

    const onDatesChange = useCallback(
        (dateRange: DateRange) => {
            setLoading(true);
            setDateRange(dateRange)
            if (dateRange.startDate != null && dateRange.endDate != null) {
                userBehaviorDataApiClient.getFunnelData(dateRange)
                    .then((response: Array<FunnelDataRecord>) => {
                        setFunnelData(response);
                        const appIds: Option[] = getAppId(response);
                        setAppIds(appIds);

                        if (ready) {
                            setAppId(null);
                            setAppEventsSelected([]);
                            setReady(false);
                        }
                    })
                    .catch((reason) => {
                        alertContext.showErrorAlert(reason.message);
                    }).finally(() => { setLoading(false) });
            }
        }, [ready]);

    const onAppIdSelectChange = useCallback(
        (option) => {
            const appEvents = getFunnelAppEvents(funnelData, option);
            setAppEvents(appEvents);
            setAppId(option);

            if (ready) {
                setAppEventsSelected([]);
                setReady(false);
            }
        }, [funnelData, ready]);

    const onAppEventSelectChange = useCallback(
        (options) => {
            setAppEventsSelected(options);
            if (options === null || options?.length <= 0) {
                setReady(false);
                return
            }

            const funnelPlotData = getFunnelPlotData(funnelData, appId as Option, options);
            setFunnelPlotData(funnelPlotData);
            setReady(true);
        }, [funnelData, appId]);

    let startMessage = null
    if (!ready) {
        startMessage = <p>{t("select dates, app ID and events to get plot")}</p>
    }

    return (
        <IfGranted expected={Rights.AnalysisFunnelRead} actual={authContext.userRights}>
            <Fragment>
                <h3>
                    {t('funnel')}
                </h3>
                <br />
                <Row>
                    <Col lg="2">
                        <Row>
                            <Col data-testid="funnel-date-picker">
                                {t("pick date range")}: <br />
                                <DatePicker
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                    onDatesChange={onDatesChange}
                                    dateFormat={t("YYYY/MM/DD")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col data-testid="funnel-app-id-select">
                                {t("app id")}:
                            <Select
                                    options={appIds}
                                    value={appId}
                                    isLoading={loading}
                                    onChange={onAppIdSelectChange}
                                    noOptionsMessage={() => t("no options")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col data-testid="funnel-app-event-select">
                                {t("events")}:
                            <Select
                                    options={appEvents}
                                    isLoading={loading}
                                    isMulti
                                    onChange={onAppEventSelectChange}
                                    value={appEventsSelected}
                                    noOptionsMessage={() => t("no options")}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md="10" lg="10">
                        <Tabs defaultActiveKey="plot" data-testid="funnel-tabs">
                            <Tab eventKey="plot" title={t("plot")} data-testid="funnel-plot-tab" className={'text-center'}>
                                {startMessage}
                                <Placeholder showLoadingAnimation={loading} ready={ready} style={{ width: "100%" }}>
                                    <Plot
                                        data={[
                                            {
                                                type: 'funnel',
                                                y: funnelPlotData.map((o) => o.label),
                                                x: funnelPlotData.map((o) => o.value),
                                            },
                                        ]}
                                        layout={{
                                            title: `Funnel plot for ${appId?.label}`,
                                            autosize: true,
                                            yaxis: {
                                                automargin: true,
                                            },
                                        }}
                                        useResizeHandler={true}
                                    />
                                </Placeholder>
                            </Tab>
                            <Tab eventKey="table" title={t("tables")} data-testid="funnel-table-tab">
                                <Table striped bordered hover size="sm" responsive>
                                    <thead data-testid="funnel-table">
                                        <tr>
                                            <th>App ID</th>
                                            <th>Source Step</th>
                                            <th>Target Step</th>
                                            <th>Sum Count</th>
                                        </tr>
                                    </thead>
                                    <tbody data-testid="funnel-data-list">
                                        {
                                            funnelData?.map((row, index) =>
                                                <tr key={`funnel-data-list-row-${index}`}>
                                                    <td>{row.app_id}</td>
                                                    <td>{row.source_step}</td>
                                                    <td>{row.target_step}</td>
                                                    <td>{row.sum_count}</td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Tab>
                        </Tabs>

                    </Col>
                </Row>
            </Fragment>
        </IfGranted>
    )
}

export default Funnel;