import React, { Fragment, useCallback, useContext, useState, useEffect } from 'react';
import { Col, Row, Tabs, Tab, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Plot from 'react-plotly.js';
import Select from 'react-select';
import moment from 'moment';
import userBehaviorDataApiClient from '../../apiClient/UserBehaviorDataClient';
import { DatePicker } from '../../components/UI/DatePicker';
import { Placeholder } from '../../components/UI/Placeholder';
import { AlertContext } from '../../context/AlertContext';
import { AuthContext } from '../../context/AuthContext';
import { getAppId, getBehaviorAppEvents, getBehaviorPlotData } from './analysis';
import { Rights } from '../../auth/Rights';
import { IfGranted } from 'react-authorization'

// This is js file instead of typescript by purpose, because @types/react-plotly.js library doesn't support parcats chart type

// Maximal number of steps in data pulled from database
const MAX_STEPS = 10;
const DEFAULT_STEPS = 5;

const STEP_OPTIONS = Array.from(Array(MAX_STEPS).keys()).map((v) => ({ label: `${v + 1}`, value: v + 1 }));

const defaultDateRange = { startDate: moment().subtract(30, "day"), endDate: moment() };
const defaultSelectedApp = { value: "com.tcl.tclplus", label: "tclplus" };
const defaultFirstStep = { value: "RecommendViewController", label: "RecommendView" };
const defaultLastStep = { value: "MallViewController", label: "MallView" };

function calculateHeight(link) {
    const minHeight = 300;
    const firstRow = link.source.filter(e => !link.target.includes(e));
    const magnitude = Math.log10(firstRow.length + 1);
    const height = minHeight * (1 + 1.5 * magnitude);
    return height;
}

const Behavior = () => {

    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
    const [behaviorData, setBehaviorData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ready, setReady] = useState(false);
    const [appIds, setAppIds] = useState([]);
    const [appId, setAppId] = useState(null);
    const [steps, setSteps] = useState(DEFAULT_STEPS);
    const [firstEvents, setFirstEvents] = useState([]);
    const [lastEvents, setLastEvents] = useState([]);
    const [firstEvent, setFirstEvent] = useState(null);
    const [lastEvent, setLastEvent] = useState(null);
    const [behaviorPlotData, setBehaviorPlotData] = useState({
        label: [],
        link: {
            source: [],
            target: [],
            value: [],
        }
    });

    function resetPlotState() {
        setAppId(null);
        setFirstEvents([]);
        setLastEvents([]);
        setFirstEvent(null);
        setLastEvent(null);
        setReady(false);
    }

    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);

    const { t } = useTranslation();

    useEffect(() => {
        setLoading(true);
        setDateRange(defaultDateRange);
        if (authContext.isAuthenticated) {
            userBehaviorDataApiClient.getBehaviorData(defaultDateRange, MAX_STEPS)
                .then((response) => {
                    setBehaviorData(response);

                    const appIds = getAppId(response);
                    setAppIds(appIds);
                    setAppId(defaultSelectedApp);

                    setFirstEvents(getBehaviorAppEvents(response, defaultSelectedApp, 1));
                    setLastEvents(getBehaviorAppEvents(response, defaultSelectedApp, DEFAULT_STEPS));
                    setFirstEvent(defaultFirstStep);
                    setLastEvent(defaultLastStep);

                    const behaviorPlotData = getBehaviorPlotData(response, defaultSelectedApp, DEFAULT_STEPS, defaultFirstStep, defaultLastStep);
                    setBehaviorPlotData(behaviorPlotData);
                    setReady(true);
                })
                .catch((reason) => {
                    alertContext.showErrorAlert(reason.message);
                }).finally(() => { setLoading(false) });
        }
    }, [authContext.isAuthenticated, alertContext]);

    const onDatesChange = useCallback(
        (dateRange) => {
            setLoading(true);
            setDateRange(dateRange)
            if (dateRange.startDate != null && dateRange.endDate != null) {
                if (ready) {
                    resetPlotState();
                }
                userBehaviorDataApiClient.getBehaviorData(dateRange, MAX_STEPS)
                    .then((response) => {
                        setBehaviorData(response);
                        const appIds = getAppId(response);
                        setAppIds(appIds);
                    })
                    .catch((reason) => {
                        alertContext.showErrorAlert(reason.message);
                    }).finally(() => setLoading(false));
            }
        }, [ready]);

    const onAppIdSelectChange = useCallback(
        (option) => {
            if (ready) {
                resetPlotState();
            }
            setAppId(option);
            setFirstEvents(getBehaviorAppEvents(behaviorData, option, 1));
            setLastEvents(getBehaviorAppEvents(behaviorData, option, steps));
            const behaviorPlotData = getBehaviorPlotData(behaviorData, option, steps);
            setBehaviorPlotData(behaviorPlotData);
            setReady(true);
        }, [behaviorData, ready, steps]);

    const onStepSelectChange = useCallback(
        (option) => {
            setSteps(option.value);

            if (appId === null)
                return

            const behaviorPlotData = getBehaviorPlotData(behaviorData, appId, option.value);
            setBehaviorPlotData(behaviorPlotData);
            setReady(true);
        }, [behaviorData, appId]);

    const onFirstEventSelectChange = useCallback(
        (option) => {
            setFirstEvent(option);

            if (appId === null)
                return

            const behaviorPlotData = getBehaviorPlotData(behaviorData, appId, steps, option, lastEvent);
            setBehaviorPlotData(behaviorPlotData);
            setReady(true);
        }, [behaviorData, appId, steps, lastEvent]);

    const onLastEventSelectChange = useCallback(
        (option) => {
            setLastEvent(option);

            if (appId === null)
                return

            const behaviorPlotData = getBehaviorPlotData(behaviorData, appId, steps, firstEvent, option);
            setBehaviorPlotData(behaviorPlotData);
            setReady(true);
        }, [behaviorData, appId, steps, firstEvent]);

    let startMessage = null
    if (!ready) {
        startMessage = <div>{t("select dates, app ID and steps to get plot")} </div>
    }

    return (
        <IfGranted expected={Rights.AnalysisUserBehaviorRead} actual={authContext.userRights}>
            <Fragment>
                <h3>
                    {t('behavior')}
                </h3>
                <br />
                <Row>
                    <Col lg="2">
                        <Row>
                            <Col data-testid="behavior-date-picker">
                                {t("pick date range")}: <br />
                                <DatePicker
                                    startDate={dateRange.startDate}
                                    endDate={dateRange.endDate}
                                    onDatesChange={onDatesChange}
                                    dateFormat={t("YYYY/MM/DD")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col data-testid="behavior-app-id-select">
                                {t("app id")}:
                            <Select
                                    options={appIds}
                                    isLoading={loading}
                                    onChange={onAppIdSelectChange}
                                    value={appId}
                                    noOptionsMessage={() => t("no options")}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col data-testid="behavior-steps-select">
                                {t("steps")}:
                            <Select
                                    defaultValue={{ label: `${steps}`, value: steps }}
                                    options={STEP_OPTIONS}
                                    isLoading={loading}
                                    onChange={onStepSelectChange}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col data-testid="behavior-fist-step-select">
                                {t("first step")}:
                            <Select
                                    options={firstEvents}
                                    isLoading={loading}
                                    onChange={onFirstEventSelectChange}
                                    value={firstEvent}
                                    noOptionsMessage={() => t("no options")}
                                    isClearable
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col data-testid="behavior-last-step-select">
                                {t("last step")}:
                            <Select
                                    disabled={true}
                                    options={lastEvents}
                                    isLoading={loading}
                                    onChange={onLastEventSelectChange}
                                    value={lastEvent}
                                    noOptionsMessage={() => t("no options")}
                                    isClearable
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col md="10" lg="10">
                        <Tabs defaultActiveKey="plot" data-testid="behavior-tabs">
                            <Tab eventKey="plot" title={t("plot")} data-testid="behavior-plot-tab" className={'text-center'}>
                                {startMessage}
                                <Placeholder showLoadingAnimation={loading} ready={ready} style={{ width: "100%" }}>
                                    <Plot
                                        data={[
                                            {
                                                type: 'sankey',
                                                orientation: 'h',
                                                node: {
                                                    label: behaviorPlotData.label,
                                                },
                                                link: behaviorPlotData.link,
                                            }
                                        ]}
                                        layout={{
                                            title: `Behavior plot for ${appId?.label}`,
                                            autosize: true,
                                        }}
                                        useResizeHandler={true}
                                        style={{
                                            height: calculateHeight(behaviorPlotData.link),
                                        }}
                                    />
                                </Placeholder>
                            </Tab>
                            <Tab eventKey="table" title={t("tables")} data-testid="behavior-table-tab">
                                <Table striped bordered hover size="sm" responsive>
                                    <thead data-testid="behavior-table">
                                        <tr>
                                            <th>App ID</th>
                                            <th>Step Index</th>
                                            <th>Source Step</th>
                                            <th>Target Step</th>
                                            <th>Sum Count</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            behaviorData?.sort((left, right) => (left.step_index - right.step_index))
                                                .sort((left, right) => (left.appId > right.appId ? -1 : 1))
                                                .map((row, index) =>
                                                    <tr key={`behavior-data-list-row-${index}`}>
                                                        <td>{row.app_id}</td>
                                                        <td>{row.step_index}</td>
                                                        <td>{row.source_step}</td>
                                                        <td>{row.target_step}</td>
                                                        <td>{row.sum_count}</td>
                                                    </tr>
                                                )
                                        }
                                    </tbody>
                                </Table>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Fragment>
        </IfGranted>
    )
}

export default Behavior;
