import React, { useState, useCallback } from 'react';
import User from "./interfaces"
import EditUserForm from "./EditUserForm"
import UserRow from "./UserRow"

interface UserRowContainerProps {
    user: User,
    onUserDeleted: (userId: string) => void,
    onUserEdited: (user: User) => void
}

const UserRowContainer = (props: UserRowContainerProps) => {
    const user = props.user;

    const [editing, setEditing] = useState(false);

    let userDisplay;

    const handleUserEdited = useCallback(
        (user: User) => {
            props.onUserEdited(user);
            setEditing(false);
        },
        [props],
    );

    if (editing) {
        userDisplay =
            <EditUserForm
                user={user}
                onCancelClick={() => setEditing(false)}
                onUserEdited={handleUserEdited}
            />
    }
    else {
        userDisplay =
            <UserRow
                user={user}
                onEditClick={() => setEditing(true)}
                onUserDeleted={props.onUserDeleted}
            />;
    }
    return (<tr>{userDisplay}</tr>);
}

export default UserRowContainer;