import React, { Fragment, useCallback, useState, useContext } from 'react';
import User from "./interfaces"
import EditIcon from '../../../components/Icons/EditIcon';
import DeleteConfirmationModal from '../../../components/Modal/DeleteConfirmationModal';
import DeleteIcon from '../../../components/Icons/DeleteIcon';
import { AlertContext } from '../../../context/AlertContext';
import { AuthContext } from '../../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import accessControlManagementApiClient from '../../../apiClient/AccessControlManagementClient';
import { RoleByRoleId, getRoleType } from './RoleByRoleId';
import DateTimeInTimezone from '../../../utils/DatetimeTz';
import { IfGranted } from 'react-authorization'
import { Rights } from '../../../auth/Rights';
import '../../../styles/WrapAnywhere.css';

interface UserRowProps {
    user: User,
    onEditClick: (event: any) => void,
    onUserDeleted: (userId: string) => void
}

function UserRow(props: UserRowProps) {
    const user = props.user

    const alertContext = useContext(AlertContext);
    const authContext = useContext(AuthContext);

    const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

    const { t } = useTranslation();

    const handleDeleteButtonClicked = useCallback((event: any) => {
        event.preventDefault();

        accessControlManagementApiClient.deleteUser(user.id)
            .then((response: any) => {
                props.onUserDeleted(user.id);
                alertContext.showSuccessAlert(t('removed user', { name: user.name }));
            })
            .catch((reason) => {
                alertContext.showErrorAlert(t("problem with deleting user from server"));
            });
    }, [props, user]);


    return (
        <Fragment>
            <td>{user.name}</td>
            <td className="wrap-anywhere">{user.id}</td>
            <td>{user.email}</td>
            <td>{user.description}</td>
            <td><RoleByRoleId roleId={user.roleId} getAttrFunc={getRoleType} /></td>
            <td><DateTimeInTimezone utcDateTime={user.updateTime} /></td>
            <IfGranted expected={Rights.UserWrite} actual={authContext.userRights}>
                <td>
                    <EditIcon onClick={props.onEditClick} />
                    <DeleteIcon onClick={(_: any) => setShowDeleteConfirmationModal(true)} />
                    <DeleteConfirmationModal
                        show={showDeleteConfirmationModal}
                        heading={t("delete user")}
                        body={t("do you want to delete user?")}
                        onCancelButtonClicked={(_: any) => setShowDeleteConfirmationModal(false)}
                        onDeleteButtonClicked={handleDeleteButtonClicked}
                    />
                </td>
            </IfGranted>
        </Fragment>
    );
}

export default UserRow