import React, { useState, useCallback, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Project from './interfaces';
import trackingConfigurationApiClient from '../../../apiClient/TrackingConfigurationClient';
import SmallErrorMessage from '../../../components/Forms/SmallErrorMessage';
import { AlertContext } from '../../../context/AlertContext';
import ProjectTypeSelect from '../components/ProjectTypeSelect';
import { useTranslation } from 'react-i18next';
import { SelectOptions } from './fixtures';
import usersCache from '../../AccessManagement/Users/usersCache';
import SelectSearch from '../../../components/Forms/SelectSearch';
import User from '../../AccessManagement/Users/interfaces';

interface AddProjectFormProps {
    onProjectAdded: (project: Project) => void
    handleCloseAddProjectModal: () => void
    showAddProjectModal: boolean
}

const AddProjectForm = (props: AddProjectFormProps) => {
    const alertContext = useContext(AlertContext);

    // appPackageName: string,
    // isOverseas: boolean,
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [userId, setUserId] = useState('');
    const [projectType, setProjectType] = useState('');
    const [appName, setAppName] = useState('');
    const [appPackageName, setAppPackageName] = useState('');
    const [isOverseas, setIsOverseas] = useState(false)
    const [forwardToSensorsData, setForwardToSensorsData] = useState(false)

    const [nameIsInvalid, setNameIsInvalid] = useState(false);
    const [descriptionIsInvalid, setDescriptionIsInvalid] = useState(false);
    const [userIdIsInvalid, setUserIdIsInvalid] = useState(false);
    const [projectTypeIsInvalid, setProjectTypeIsInvalid] = useState(false);
    const [appNameIsInvalid, setAppNameIsInvalid] = useState(false);
    const [appPackageNameIsInvalid, setAppPackageNameIsInvalid] = useState(false);

    const [invalidProjectNameMessage, setInvalidProjectNameMessage] = useState('')

    const { t } = useTranslation();

    const handleAddProjectButtonClick = useCallback((event: any) => {
        event.preventDefault();

        let formIsValid = true;

        if (name.trim() === '') {
            setNameIsInvalid(true);
            setInvalidProjectNameMessage(t("project name can't be empty"))
            formIsValid = false;
        }
        else {
            setNameIsInvalid(false);
        }

        if (description.trim() === '') {
            setDescriptionIsInvalid(true);
            formIsValid = false;
        }
        else {
            setDescriptionIsInvalid(false);
        }

        if (userId.trim() === '') {
            setUserIdIsInvalid(true);
            formIsValid = false;
        }
        else {
            setUserIdIsInvalid(false);
        }

        if (projectType.trim() === '') {
            setProjectTypeIsInvalid(true);
            formIsValid = false;
        }
        else {
            setProjectTypeIsInvalid(false);
        }

        if (appName.trim() === '') {
            setAppNameIsInvalid(true);
            formIsValid = false;
        }
        else {
            setAppNameIsInvalid(false);
        }

        if (appPackageName.trim() === '') {
            setAppPackageNameIsInvalid(true);
            formIsValid = false;
        }
        else {
            setAppPackageNameIsInvalid(false);
        }

        if (formIsValid) {
            trackingConfigurationApiClient.addProject({
                name: name,
                description: description,
                projectType: projectType,
                userId: userId,
                appName: appName,
                appPackageName: appPackageName,
                isOverseas: isOverseas,
                forwardToSensorsData: forwardToSensorsData
            })
                .then((response: Project) => {
                    props.onProjectAdded(response);
                    alertContext.showSuccessAlert(t('project was successfully added', { name: name }));
                    props.handleCloseAddProjectModal();

                    // clear form
                    setName('');
                    setDescription('');
                    setUserId('');
                    setProjectType('');
                    setAppName('');
                    setAppPackageName('');
                    setIsOverseas(false);
                    setIsOverseas(false);
                })
                .catch((reason) => {
                    const stringifyMessage = JSON.stringify(reason.message)
                    if (stringifyMessage.includes("already exists")) {
                        setNameIsInvalid(true);
                        setInvalidProjectNameMessage(t("project name already exists", { name: name }))
                    }
                    else {
                        props.handleCloseAddProjectModal();
                        alertContext.showErrorAlert(stringifyMessage);
                    }
                })
        }
    }, [name, description, userId, projectType, appName, appPackageName, isOverseas, forwardToSensorsData]);

    return (
        <Modal
            show={props.showAddProjectModal}
            onHide={props.handleCloseAddProjectModal}
            keyboard={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("add project")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="name" className="required">
                        <Form.Label>{t("project name")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("enter project name")}
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            data-cy="project-name-text-box"
                            autoComplete="off"
                        />
                        <SmallErrorMessage show={nameIsInvalid} message={invalidProjectNameMessage} />
                    </Form.Group>

                    <Form.Group controlId="description" className="required">
                        <Form.Label>{t("description")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("enter project description")}
                            value={description}
                            onChange={(event) => setDescription(event.target.value)}
                            autoComplete="off"
                        />
                        <SmallErrorMessage show={descriptionIsInvalid} message={t("project description can't be empty")} />
                    </Form.Group>

                    <Form.Group controlId="userName" className="required">
                        <Form.Label>{t("user name")}</Form.Label>
                        <SelectSearch<User>
                            value={userId}
                            onChange={setUserId}
                            placeholder={t("enter user name")}
                            cache={usersCache}
                            classNamePrefix="user"
                        />
                        <SmallErrorMessage show={userIdIsInvalid} message={t("user name can't be empty")} />
                    </Form.Group>

                    <Form.Group controlId="projectType" className="required">
                        <Form.Label>{t("project type")}</Form.Label>
                        <ProjectTypeSelect
                            value={projectType}
                            onChange={setProjectType}
                        />
                        <SmallErrorMessage show={projectTypeIsInvalid} message={t("choose project type")} />
                    </Form.Group>

                    <Form.Group controlId="appName" className="required">
                        <Form.Label>{t("app name")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("enter app name")}
                            value={appName}
                            onChange={(event) => setAppName(event.target.value)}
                            autoComplete="off"
                        />
                        <SmallErrorMessage show={appNameIsInvalid} message={t("app name can't be empty")} />
                    </Form.Group>

                    <Form.Group controlId="appPackageName" className="required">
                        <Form.Label>{t("app package name")}</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("enter app package name")}
                            value={appPackageName}
                            onChange={(event) => setAppPackageName(event.target.value)}
                            autoComplete="off"
                        />
                        <SmallErrorMessage show={appPackageNameIsInvalid} message={t("app package name can't be empty")} />
                    </Form.Group>

                    <Form.Group controlId="isOverseas">
                        <Form.Label>{t("is overseas")}</Form.Label>
                        <Form.Control
                            as="select"
                            type="text"
                            value={String(isOverseas)}
                            onChange={(event) => setIsOverseas(JSON.parse(event.target.value))}
                        >
                            <option value="false">{SelectOptions.IsOverseas.false}</option>
                            <option value="true">{SelectOptions.IsOverseas.true}</option>
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="forwardToSensorsData">
                        <Form.Label>{t("forward to sensors data")}</Form.Label>
                        <Form.Control
                            as="select"
                            type="text"
                            value={String(forwardToSensorsData)}
                            onChange={(event) => setForwardToSensorsData(JSON.parse(event.target.value))}
                        >
                            <option value="false">{SelectOptions.ForwardToSensorsData.false}</option>
                            <option value="true">{SelectOptions.ForwardToSensorsData.true}</option>
                        </Form.Control>
                    </Form.Group>

                </Form>
            </Modal.Body>

            <Modal.Footer>
                <Button className="btn btn-secondary mr-auto" onClick={props.handleCloseAddProjectModal}>{t('cancel')}</Button>
                <Button variant="primary" type="submit" onClick={handleAddProjectButtonClick} >{t("submit")}</Button>
            </Modal.Footer>

        </Modal>
    );
}

export default AddProjectForm;