import React from 'react';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from './ConfirmationModal';


interface DeleteConfirmationModalProps {
    show: boolean,
    heading: string,
    body: any,
    onCancelButtonClicked: (event: any) => void,
    onDeleteButtonClicked: (event: any) => void,
}

const DeleteConfirmationModal = (props: DeleteConfirmationModalProps) => {
    const { t } = useTranslation();
    return(
        <ConfirmationModal
            show={ props.show }
            heading={ props.heading }
            body={ props.body }
            firstButton={{
                variant: "secondary",
                text: t('cancel'),
                onClick: props.onCancelButtonClicked
            }}
            secondButton={{
                variant: "danger",
                text: t('delete'),
                onClick: props.onDeleteButtonClicked
            }}
        />
    );
};

export default DeleteConfirmationModal;
